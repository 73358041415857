@media (max-width: 1919.98px) {
  .full_container {
    max-width: 100%;
  }

  /*    .home_banner_block:after{ width:51rem;}*/
  .how_sec:before {
    top: 25px;
  }

  .home_banner_block:after {
    background-position: right -19.75rem bottom;
  }
}

@media (max-width: 1599.98px) {

  .dashboard_inner .mentor_img{height:240px;}

  /*.home_banner_block:after{ width:50rem; }*/
  .how_img {
    width: 42%;
  }

  .jumbotron_leftsec {
    width: 40%;
  }

  .message-bar-head {
    width: 60%;
  }

  .how_sec:before {
    top: 22px;
  }

  .home_banner_block:after {
    background-position: right -25.75rem bottom;
  }

  .mentoring_banner_caption h1 {
    font-size: 38px;
  }

}

@media screen and (max-width: 1439.98px) {

  .dashboard_inner .mentor_img{height:200px;}

  .mentoring_banner_caption h1 {
    font-size: 32px;
  }

  .date_list li {
    width: 49%;
    padding-top: 0.5rem;
  }

  /* .message-bar-head {
    width: 66%;
  } */
  .mentor_modal .mentor_name h3 {
    font-size: 25px;
  }

  .mentor_modal .mentor_name {
    padding: 1rem;
  }

  .session_outer p+p {
    margin-left: 50px;
  }

  /*    .home_banner_block:after{ width:42rem; background-position:0 0; }*/
  .banner_video {
    width: 36rem;
    height: 36rem;
  }
}

@media (max-width: 1365.98px) {


  .how_img {
    top: 43px;
}

.about_how_content {
    top: 0;
}

  .dashboard_inner .mentor_img {
    height: 180px;
}

  .dashboard_inner .mentor_img{height:180px;}

  .mentro_details .btn {
    font-size: 14px;
    padding: 8px 12px;
  }

  .dash_MentorList .col {
    width: 25%;
  }

  .session_outer p+p {
    margin-left: 15px;
  }

  .session_outer p h4 span {
    font-size: 16px;
  }

  .session_outer p h4 {
    font-size: 18px;
  }

  .mentor_session {
    margin-top: 20px;
    padding-top: 20px;
  }

  .banner_video {
    width: 32rem;
    height: 32rem;
    right: -3rem;
  }

  /*    .home_banner_block:after{width:37rem;}*/
  .how_img {
    position: relative;
    width: 100%;
    z-index: 1;
  }




  .about_how_work {
    min-height: auto;
    padding: 5rem 0rem;
  }

  .about_how_work:before {
    height: 100%;
  }

  .about_who_sec {
    padding: 7rem 0rem 14rem;
  }

  .about_how_work {
    padding: 4rem 0rem;
  }

  .about_how_work:after {
    top: 30%;
  }

  .how_sec:before {
    top: 19px;
  }

  .menteesCols{flex:0 0 33.333%; max-width:33.333%;}
}

@media (max-width: 1199.98px) {

  .how_img {
    top: 14px;
}
.how_list li p {
  line-height: 1.4;
}

.about_how_content {
  padding-left: 15px;
}
 

  .mentoring_banner_caption h1 {
    font-size: 30px;
  }

  .scane_qr {
    padding-top: 15px;
  }

  .scane_qr .qr_scanbox {
    width: 80px;
  }

  .scane_qr h6 {
    margin-bottom: 10px;
  }

  .download_app_detail p {
    font-size: 20px;
  }

  .app_store_link a img {
    max-height: 46px;
}

  /* partnership-page-css */

  .offer_img figure:after {
    right: -26px;
  }

  .offer_2 .offer_img figure:after {
    left: -23px;
  }

  .offer_inside::after {
    height: 100%;
  }

  .offer_1 .offer_img::after {
    height: 100%;

    z-index: 0;
  }

  /* 18/8/22 */

  .inner-partner {
    font-size: 2.8rem;
    margin-bottom: 2rem;
  }

  .why-reson .inner-partner {
    margin-bottom: 4rem;
  }

  .chat-sec::before {
    width: 192px;
    height: 169px;
    left: -30px;
    top: -44px;
  }

  .exicted-sec .exicted-right-panel li:last-child::after {
    left: -58px;
  }

  .exicted-right-panel ul::after {
    height: 84px;
  }

  .exicted-sec .exicted-right-panel li>div {
    padding: 31px 23px;
  }

  .exicted-sec .exicted-right-panel li::before {
    left: -70px;
  }

  .exicted-sec {
    padding: 0px 0px 20px;
  }

  .why-reson ul li {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }

  .partnership-page-inner p {
    line-height: 25px;
  }

  /* 18/8/22 */

  /* partnership-page-css */

  .dash_MentorList .col {
    width: 33.33%;
  }

  .jumbotron_leftsec,
  .jumbotron_rightsec {
    min-height: auto;
  }


  .mentor_modal .mentor_pic {
    max-width: 350px;
    margin: 0 0 20px;
  }

  .session_outer .review_btn {
    float: none;
    margin-top: 15px;
  }

  .session_outer p+p {
    margin-left: auto;
  }

  .session_outer .review_btn {
    float: none;
    text-align: center;
    margin: 15px auto 0;
    display: block;
    max-width: 290px;
  }

  .studentformdashboard span.uploadfile {
    top: 25px;
    padding: 17px 25px 16px;
  }

  html {
    font-size: 12px;
  }

  .name_info {
    padding-bottom: 1rem;
  }

  .profile_btn .btn:first-child {
    margin-left: 0;
  }

  .mentor_box .Scheduled_box h3 {
    width: 100%;
  }

  .Scheduled_box .date_Resulte_main {
    flex-wrap: wrap;
  }

  .dropedown_day {
    margin-top: 0.8rem;
  }

  .date_Resulte {
    width: 100%;
    justify-content: space-between;
  }

  .dropedown_day {
    margin-left: inherit !important;
  }

  /*.navigation .navbar-nav li{padding: 0rem 1.2rem;}*/
  .banner_title {
    font-size: 3rem;
  }

  .banner_video {
    width: 28rem;
    height: 28rem;
  }

  .title {
    font-size: 3rem;
  }

  /*.home_banner_block:after{ width:32rem;}*/
  .home_banner_block:after {
    background-position: right -42.75rem bottom;
  }

  .achievements_list li{padding:0 15px;}
  .descraption {
    font-size: 1.7rem;
  }

  .home_banner_block {
    background-size: 6rem;
  }

  .how_sec {
    padding: 5rem 0rem 0rem;
  }

  .leader_sec {
    padding: 8rem 0rem 6rem 0;
  }

  .brand_sec {
    padding: 4rem 0rem 8rem;
  }

  .space-bottom {
    padding-bottom: 4rem;
  }

  .student_sec {
    padding: 3rem 0rem 7rem;
  }

  .about_content_mian {
    padding-top: 10rem;
  }

  .about_content_mian .about_content {
    padding: 0;
  }

  .about_how_work {
    padding: 5rem 0rem;
  }

  .about_who_sec {
    padding: 5rem 0rem 10rem;
  }

  /*.members_sec{ padding-bottom:8rem;}*/
  .about_how_work:after {
    display: none;
  }

  .about_who_sec:before {
    display: none;
  }

  .home_banner_block:before {
    display: none;
  }

  .how_sec:before {
    top: 16px;
  }

  .mentor_modal .mentor_name {
    padding: 1rem;
  }

  .mentor_modal .modal-content {
    padding: 2rem;
  }

  .mentor_modal .modal-dialog {
    max-width: 100%;
  }

  .mentor_img img {
    width: 100%;
  }

  .toggle_btn_outer.chat_main {
    position: inherit;
  }

  .logo img {
    max-width: 150px;
  }

  .navbar-light .navbar-nav .nav-link {
    font-size: 13px;
  }

  .btn_join {
    min-width: 10rem;
    font-size: 14px;
  }

  .download_app_detail {
    padding-bottom: 50px;
  }



  .offer_img {
    width: 36%;
  }

  .offer_content {
    width: 64%;
  }

  .benifits_list .offer_img figure {
    padding-right: 15px;
    padding-left: 15px;
  }

  .benifits_list .offer_img figure span img {
    max-height: 54px;
  }

  .benifits_list .offer_content p {
    font-size: 18px;
  }
  .programs_are_driven_point li a{font-size:15px;}

  .menteesCols{flex:0 0 50%; max-width:50%;}
.dashboard_inner .mentor_img {
    height: 220px;
}
.hover-part span.profile {
  font-size: 20px;
}

.bannerShapeImg {
  max-width: 520px;
}
.bannerShapelogo{
  left: 102px;
  bottom: 77px;
}


.DOIDBannerContent h1 {
  font-size: 28px;
}

.DOIDBannerContent h1 small {
  font-size: 18px;
}

.DOIDBannerContent h1 strong {
  font-size: 32px;
}

.DoIdLogoWrapper img {
  max-height: 28px;
}

.DoIdLogoWrapper {
  min-height: 40px;
  padding: 10px 15px;
  border-radius: 6px;
  margin-top: 10px;
}

.powered-champianed {
  margin-top: 15px;
}

.powered-champianed span {
  font-size: 14px;
}


}

@media (max-width: 991.98px) {

  .bannerShapelogo {
    left: 80px;
    bottom:40px;
}

.bannerShapelogo img{max-height:64px;}

  .bannerShapeImg {
    max-width: 360px;
  }
  .igniteSectionOuter {
    padding-top: 76px;
}
.poweredBY{margin-bottom:20px;}

.banner-Cap{padding-top:24px;}
  .banner-Cap h1{font-size:24px;}
  .banner-Cap{max-width:64%;}
  .poweredBY span img{max-height:30px;}

  .slick-dots li button{padding:0px;}

  .members_sec {background: #f9f9f9;}

  .about_how_work .row {
    flex-direction: column;
}

.how_img {
    position: initial!important;
}

.about_how_work .row .how_left.col-md-5,.about_how_work .row .col-md-7 {flex: 0 0 100%;max-width: 100%;}

.about_how_work:before {
    display: none;
}

.about_how_content {
    padding-left: 0;
}

.how_img {
    width: 100%!important;
}

section.about_how_work {
    background: #f9f9f9;
    margin: 32px 0;
}

.about_how_content {
    padding-top: 20px;
}

.how_list li .heading{line-height:1.4;}

  .slick-prev:before,.slick-next:before{width:16px; height:16px;}

  .programs_are_driven_point li a {
    font-size: 16px;
    line-height: 1.4;
  }

  .mentoring_banner_caption h1 {
    line-height: 1.4;
  }

  .app_store_link a img {
    max-height: 36px;
  }

  .download_app_detail strong:not(.qr-wrapper strong) {
    font-size: 40px;
  }

  .scane_qr {
    padding-top: 0px;
  }

  /* parternship-page-css */


  .we_offer {
    padding: 50px 0 0;
  }

  section.we_offer.partner_ship {
    padding-bottom: 74px;
  }

  .we_offer .d-flex {
    flex-wrap: wrap;
  }

  .offer_img {
    width: 65%;
    padding: 0 15px;
    margin: 0 auto 30px;
  }

  .offer_content {
    width: 100%;
    padding: 0 15px;
  }

  .offer_2 .offer_img::after {
    display: none;
  }

  .offer_inside::after,
  .offer_1 .offer_img::after,
  .offer_1 .offer_img::before,
  .offer_inside::before {
    display: none;
  }

  .offer_tool::after {
    display: none;
  }

  .offer_tool::before {
    display: none;
  }

  .we_offer .d-flex::before {
    display: none;
  }

  .offer_2 .offer_img::before {
    display: none;
  }

  .we_offer .d-flex {
    padding: 45px 0;
  }

  .offer_main {
    position: relative;
  }

  /* .offer_main:before {
    position: absolute;
    content: "";
    height: 90%;
    width: 2px;
    border: 2px dashed #a5bbe6;
    left: 50%;
    margin-left: -1px;
    bottom: 0;
  }

  .offer_main:after {
    position: absolute;
    content: "";
    height: 250px;
    width: 10px;
    left: 50%;
    background-color: #fff;
    margin-left: -5px;
    bottom: 0;
    z-index: 0;
  } */

  .we_offer .d-flex:first-child .offer_content:after {
    display: none;
  }

  .offer_img {
    margin: 0 auto;
  }

  .offer_content {
    padding-top: 30px;
    background-color: #fff;
    z-index: 8;
  }

  .offer_content:after {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    border-radius: 100%;
    background-color: #a5bbe6;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
  }

  .offer_content p:last-child {
    margin-bottom: 5px;
  }

  /* 18/8/22 */
  .partner-community .content_inner .back_img {
    height: 517px;
  }

  .inner-partner {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }

  .partnership-page-inner p {
    font-size: 1.3rem;
    line-height: 22px;
  }

  .why-reson ul:before,
  .why-reson ul:after {
    background-size: 36px;
  }

  .why-reson ul li::before {
    left: -39px;
  }

  .why-reson ul {
    padding-left: 5.84rem;
  }

  .chat-sec {
    padding-left: 5rem;
    margin-top: 5rem;
  }

  .why-reson .row {
    align-items: center;
  }

  .chat-sec::before {
    width: 144px;
    height: 200px;
    left: -98px;
    top: -52px;
  }

  .exicted-sec .exicted-right-panel li p {
    padding-left: 15px;
    font-size: 15px;
    width: calc(100% - 34px);
  }

  .exicted-sec .exicted-right-panel li>div:before {
    left: -19px;
    width: 42px;
    height: 48px;
    background-size: 20px;
  }

  .exicted-right-panel ul::after {
    height: 62px;
  }

  .exicted-sec .exicted-right-panel li figure {
    width: 34px;
  }

  .exicted-sec .exicted-right-panel li>div {
    padding: 15px 17px;
  }

  .chat-sec h3 {
    font-size: 20px;
  }

  /* 18/8/22 */

  /* parternship-page-css */


  .newsletter-html * {
    box-sizing: border-box !important;
  }



  body .newsletter-html table img {
    width: 100%;
  }

  .newsletter-html table {
    text-align: center;
    width: 100%;
  }





  .linkpart ul li {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }

  .linkpart ul {
    flex-wrap: wrap;
  }

  .linkpart-active,
  .linkpart ul li:hover {
    margin-top: 0;
    background: #fafcff !important;
  }

  .chetboxouter {
    padding: 0 !important;
  }


  .removecol_condition .col {
    flex: 0 0 100%;
    max-width: 100%;
  }


  .dash_MentorList .col {
    width: 25%;
  }

  .schedule_popup .modal-content {
    max-width: 720px;
  }

  .date_Resulte {
    width: auto;
  }

  .dropedown_day {
    margin-left: auto !important;
    margin-top: 0rem;
  }

  .dashboard_sidebar {
    left: -120px;
    transition: 0.4s ease all;
    visibility: hidden;
    opacity: 0;
  }

  .dashboard_inner {
    width: 100%;
    margin-left: 0;
    padding: 70px 20px 2.3rem;
  }

  .toggle_btn_outer {
    position: absolute;
    left: 0;
    top: 110px;
    transition: 0.4s ease all;
  }

  a.toggle_btn {
    width: 45px;
    height: 45px;
    background: #0078ff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 4px 4px 0;
    z-index: 9;
    position: fixed;
    font-size: 20px;
  }

  .show_sidebar .toggle_btn_outer {
    left: 320px;
  }

  .show_sidebar .dashboard_sidebar {
    left: 0;
    z-index: 9;
    visibility: visible;
    opacity: 1;
  }

  .show_sidebar .sidebar_overlay {
    opacity: 0.7;
    visibility: visible;
  }

  .dashboard_inner {
    min-height: 100%;
  }

  .loginform {
    width: 80%;
  }

  .whoamform {
    width: 90%;
  }

  .profilescreen {
    width: 100%;
  }

  .nextbtn {
    width: 150px;
  }

  .logo img {
    width: 12rem;
  }

  .navbar-collapse {
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: static;
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    background-color: #ffffff;
    padding: 1rem 0rem;
    box-shadow: 4px 4px 4px -6px rgba(150, 145, 145, 0.1);
  }

  .navigation .navbar-nav .nav-link {
    padding: 0.6rem 1rem;
    font-size: 1.18rem;
  }

  .header_login {
    padding: 0.7rem 1rem;
  }

  .home_banner_block {
    padding-top: 7rem;
    background-size: 5rem;
  }

  .home_banner_block:after {
    background-position: right -34.75rem bottom;
  }
  .leader_box{width:100%;}
  .title {
    font-size: 2.5rem;
}
.leader_sec .slick-list .slick-slide {
  padding: 0 10px;
}

.brand_sec {
  padding: 4rem 0rem 4rem;
}
  /*.home_banner_block:after{  width:26rem;}*/
  .banner_video {
    width: 24rem;
    height: 24rem;
    right: 0;
  }

  .descraption {
    font-size: 1.35rem;
  }

  .banner_form {
    width: 100%;
  }

  .space-bottom {
    padding-bottom: 4rem;
  }

  .how_sec:before {
    top: 10px;
  }

  .how_sec:after {
    width: 50%;
  }

  .play_box {
    margin: 0;
  }

  .how_sec {
    background-size: inherit;
    padding: 3rem 0rem;
  }

  /*.brand_sec{ padding:4rem 0rem;}*/
  .space-bottom {
    padding-bottom: 3rem;
  }

  .student_sec {
    padding: 4rem 0rem;
  }

  .start-building {
    padding: 7rem 0rem 3rem;
  }

  .navbar-expand-md>.container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  header nav.navbar {
    padding: 1.5rem 0rem;
  }

  .navigation.fixed-top .navbar-expand-md .navbar-nav {
    margin-top: 1.5rem !important;
  }

  header .collapse:not(.show) {
    display: none !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: block !important;
  }

  .navbar-expand-md .navbar-collapse {
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: static;
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }

  .navbar-expand-md .navbar-nav {
    width: 100%;
    background-color: #ffffff;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0rem 1.07rem;
  }

  .navigation .navbar-nav li {
    padding: 0.5rem 0rem;
  }

  .navigation .navbar-nav li a::before {
    display: none;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 2.5rem;
  }

  /*.members_sec{ padding-bottom:6rem;}*/
  .members_sec .slick-slider {
    padding-bottom: 4rem;
  }

  .about_how_work {
    padding: 3rem 0rem;
  }

  .how_list li {
    padding-top: 2rem;
  }

  .outer_main {
    padding: 7rem 0 2rem;
  }

  .page_heading {
    margin-bottom: 3rem;
  }

  .mail_outer {
    padding: 50px;
  }

  .mailbox {
    padding: 50px 20px;
  }

  ul.sociallink li a {
    font-size: 23px;
    width: 30px;
    height: 30px;
  }

  .navbar-collapse {
    box-shadow: 0 5px 5px rgba(0, 0, 0, .10) !important;
    background-color: #f7f7f7 !important;
  }

  .dashboard_header .container-fluid {
    padding: 0 15px;
  }


  .DashHeader_right .navbar-collapse {
    position: absolute;
    left: 0;
    right: 0;
    top: 64px;
  }

  .DashHeader_right .navbar-collapse .nav-link {
    width: 100%;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid #e7e7e7;
  }

  .dashboard_header .navbar {
    position: relative;
  }

  .achievements_list li {
    padding: 0 10px;
  }

  .achievements_list li span {
    padding: 5px 0;
    font-size: 18px;
  }

  .achievements_list li i {
    font-size: 20px;
  }

  .achievements_list li strong {
    font-size: 13px;
  }

  .achivment {
    padding: 36px 0;
  }


  /**=====faq=======**/

  .platform {
    flex-direction: column;
    justify-content: center;
  }

  .message_icon {
    margin-right: 0;
  }

  .platform h5 {
    max-width: 100%;
    text-align: center;
  }

  .faq_tabs_main .card-body {
    padding: 5px 24px 24px 40px;
  }

  .faq_tabs_main button {
    padding-left: 40px;
  }

  .faq_tabs_main button:before {
    left: 15px;
  }

  .faq_text_content ul li,
  .faq_text_content p,
  .faq_text_content p strong,
  .faq_text_content ul li strong {
    font-size: 16px;
  }

  .ans_column {
    padding: 15px;
  }

  .faq_text_content ul>li {
    padding: 12px 0 12px 30px;
  }

  .faq_page .nav.nav-tabs .nav-item {
    padding: 16px 36px;
    font-size: 18px;
  }

  .faq_page .nav.nav-tabs {
    padding: 36px 0;
  }

  .resource_hub_grid {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }

  .resource_hub {
    display: flex;
    flex-wrap: wrap;
  }

  .download_app_detail strong::not(.qr-wrapper strong) {
    font-size: 36px;
  }

  .download_app_detail p {
    font-size: 22px;
    padding-bottom: 10px;
  }

  .scane_qr h6 {
    font-size: 24px;
    margin-bottom: 10px;
  }



  .scane_qr .qr_scanbox {
    width: 94px;
  }

  .qr-wrapper {
    margin-right: 24px;
  }

  .download_app_detail {
    padding-bottom: 32px;
  }

  .app_screen {
    position: relative;
    top: 12px;
    text-align:center;
  }

  .download_app_section {
    padding-top: 50px;
  }

  .newsletter_content p{font-size:18px;}

}

@media (min-width: 768px) {

  .mentor_box .slick-slider .slick-track,
  .mentor_box .slick-slider .slick-list {
    width: 100% !important;
  }
}

@media (max-width: 767.98px) {

  .who-thumb-outer.row{padding:20px 0;}

  .how_sec .icon_box .box a {
    justify-content: center!important;
}

.how_sec .icon_box .box {
    justify-content: center;
}

.how_content .icon_box img {
    max-width: 24px;
}

  .programs_are_driven {
    padding: 50px 0;
  }

  .programs_are_driven_content {
    padding-top: 24px;
  }

  .programs_are_driven_content h2 {
    margin-bottom: 10px;
  }

  .mentoring_banner_caption h1 {
    font-size: 27px;
  }

  .download_app_detail strong:not(.qr-wrapper strong) {
    font-size: 32px;
  }

  .download_app_detail {
    display: inline-block;
    position: relative;
    top: -10px;
  }


  .download_app_detail p {
    font-size: 17px;
  }

  .scane_qr h6 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .app_store_link img {
    max-width: 110px;
  }

  .app_store_link {
    padding: 0;
  }

  .scane_qr .qr_scanbox {
    width: 90px;
    padding: 5px;
  }

  .download_app_detail {
    padding-bottom: 24px;
  }


  /* partnership-page-css */

  .collapse_toggle_inner {
    position: absolute;
    left: 20px;
    top: 16px;
  }

  .we_offer .title {
    margin-bottom: 28px;
  }

  .offer_main {
    background-color: #fff;
    padding: 30px 20px 0;
  }

  /* 18/8/22 */

  .exicted-sec .inner-partner {
    margin-bottom: 3rem;
  }

  .parter-slider .partner-img {
    max-width: 82%;
    margin: 0 auto 2rem;
  }

  .slider-content h2 {
    font-size: 21px;
  }

  .slider-content h3 {
    line-height: 26px;
    font-size: 17px;
  }

  .slider-content p {
    font-size: 1.4rem;
  }

  .slider-content {
    text-align: center;
  }

  .exicted-sec .exicted-right-panel li::before {
    left: -69px;
  }

  .exicted-right-panel ul::after {
    height: 54px;
  }

  .exicted-sec .exicted-right-panel li::before {
    width: 25px;
    height: 25px;
  }

  .exicted-sec figure {
    max-width: 382px;
    margin: 0 auto 35px;
  }

  .inner-partner {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .why-reson figure {
    max-width: 236px;
    margin: 0 auto;
    margin-bottom: 3rem;
  }

  .why-reson ul li {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    line-height: 23px;
  }

  .why-reson ul:before,
  .why-reson ul:after {
    background-size: 26px;
  }

  .chat-sec::before {
    display: none;
  }

  .chat-sec {
    padding-left: 0rem;
    margin-top: 5rem;
  }

  /* 18/8/22 */

  /* partnership-page-css */

  .toggle_btn_outer.chat_main {
    height: 100%;
  }

  .linkpart ul li {
    min-height: 150px !important;
  }

  .linkpart ul li a {
    padding: 0 !important;
  }

  .newsletter-html * {
    box-sizing: border-box !important;
  }

  body .newsletter-html table img {
    width: 100%;
  }

  .newsletter-html table {
    text-align: center;
    width: 100%;
  }

  body .newsletter-html table {
    max-width: 100% !important;
    table-layout: auto !important;
  }

  .MessageBox {
    padding-right: 32px;
  }

  .toggle_btn_outer.chat_main {
    top: 0;
  }

  .dashboard_outer {
    height: 100vh;
  }

  .jumbotron_leftsec {
    width: 100%;
  }

  .backbtn {
    display: block;
    position: absolute;
    top: -1px;
    left: -25px;
  }

  .chat_main {
    position: relative !important;
  }

  .chatbodyclass .jumbotron_leftsec {
    display: none;
  }

  .chatbodyclass .message-bar-head {
    display: block;
  }

  .backbtn {
    display: none;
  }

  .chatbodyclass .backbtn {
    display: block;
    z-index: 9;
    position: absolute;
    left: auto;
    right: 0;
    width: 36px;
    top: 10px;
    font-size: 17px;
    padding: 0;
    height: 36px;
    border-radius: 50px;
  }

  .message-bar-head {
    display: none;
  }

  .page_content_inner p {
    line-height: 1.2;
  }

  .resourcestabinnercontent h3 {
    font-size: 1.5rem;
  }

  .schedule_popup .modal-content {
    max-width: 540px;
  }

  .message-bar-head {
    width: 100%;
  }

  .mentro_details {
    padding: 1rem;
  }

  .meeting_form .incrase_div {
    right: 10px;
  }

  .schedule_popup .modal-content {
    padding: 1rem !important;
  }

  .schedule_popup.mentor_modal h2 {
    margin-bottom: 10px;
  }

  .meeting_form .form-group {
    margin-bottom: 1rem;
  }

  .meeting_form .btn {
    font-size: 1.35rem;
  }

  .meeting_form .form-control {
    height: 3.45rem;
    font-size: 1.14rem;
  }

  .timing_box {
    padding: 1rem;
  }

  .timing_box .heading {
    font-size: 1.4rem;
  }

  .timing_box span {
    font-size: 1.2rem;
  }

  .timing_box span+span {
    font-size: 1.35rem;
  }

  .mentro_details_leftbar {
    width: 13rem;
  }

  .mentro_details_sidebar {
    width: calc(100% - 13rem);
  }

  .mentor_box .slick-dots {
    bottom: -15px;
  }

  .mentro_details .name_info h4 {
    font-size: 1.5rem;
  }

  .banner_video {
    border-radius: 0;
  }

  .banner_video img {
    border-radius: 0;
    width:100%;
  }

  .play_box:before {
    border-radius: 0;
  }

  .dash_MentorList .col {
    width: 33.33%;
  }

  .container-fluid {
    padding: 0 15px;
  }

  .box_wrap {
    padding: 2.3rem 15px;
  }

  div#left_sidebar {
    display: none;
  }

  .sidebar_sec {
    display: block !important;
  }

  .logo img {
    width: 8rem;
  }

  .home_banner_block:after {
    display: none;
  }

  .home_banner_block {
    background: none;
    padding-bottom: 0;
    padding-top: 6rem;
  }

  .banner_form .form-control {
    height: 4rem;
    padding-left: 1rem;
    padding-right: 8.5rem;
  }

  .banner_form .btn-signup {
    font-size: 1.28rem;
  }

  .banner_form .btn-signup {
    min-width: 8rem;
  }

  .banner_title {
    font-size: 2rem;
  }

  .banner_video {
    margin: 0 auto;
    right: 0rem;
    position: relative;
    padding: 0.4rem;
    background: #cccede;
    width: 100%;
    height: auto;
  }

  .how_sec {
    padding: 3rem 0rem;
    background: none;
  }

  .how_sec:before {
    display: none;
  }

  .icon_box {
    height: 5rem;
    width: 5rem;
    border: 0.5rem solid #e9f0ff;
  }

  .icon_box img {
    max-width: 50%;
  }

  .box {
    padding-top: 1.5rem;
  }

  .descraption {
    font-size: 1.3rem;
    padding-left: 1.5rem;
    font-family: "linotte-regularuploaded_file";
  }

  .title {
    font-size: 2rem;
  }

  .title br {
    display: none;
  }

  .btn_play {
    font-size: 1.5rem;
    width: 5rem;
    height: 5rem;
  }

  .space-bottom {
    padding-bottom: 2rem;
  }

  /* .leader_sec .slick-list {
    margin: 0;
    padding: 0;
  } */

  .leader_sec .slick-prev, .communities_sec .slick-prev,.leader_sec .slick-next, .communities_sec .slick-next{top:-10px;}

  .leader_sec {
    padding: 0 0 3rem;
  }

  .leader_info {
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    bottom: 0;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .leader_info:after {
    display: none;
  }

  .leader_info span {
    font-size: 1rem;
  }

  .leader_info a {
    font-size: 1rem;
  }

  .leader_title {
    font-size: 18px;
  }
  .leader_info2 span{font-size:16px;}

  .brand_sec {
    padding: 3rem 0rem;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }

  .student_sec {
    padding: 2rem 0rem 3rem;
  }

  footer {
    padding: 1.5rem 0rem 0rem;
  }

  .footerleft img {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .quick_box {
    padding: 0.5rem 0rem;
  }

  .footertop h3 {
    margin-bottom: 1rem;
  }

  .copyright {
    padding: 12px 0px;
    margin-top: 8px;
  }

  .start-building {
    padding: 2.5rem 0rem;
    background-image: none;
  }

  .start-building:before {
    display: none;
  }

  .start-building .btn {
    /*		margin-top: 1rem;*/
    font-size: 1.42rem;
  }

  /*.slick-dots{ bottom:-35px;}*/
  .slick-dots li.slick-active button:before {
    color: #0678ff;
  }

  .slick-dots li button:before {
    opacity: 1;
    color: #ef4343;
  }

  .slick-dots li button:before {
    font-size: 10px;
  }
  .slick-dots li{width:15px; height:15px;}
  .slick-dots {
    bottom: -35px;
  }

  .slick-dots li {
    margin: 0 2px;
  }

  .how_list li .number,
  .how_list li .heading {
    font-size: 1.4rem;
  }

  .how_list li {
    padding-top: 2rem;
  }

  .about_who_sec .sub_lead {
    font-size: 1.4rem;
  }

  .about_content_mian {
    padding-top: 5rem;
  }

  .about_content_mian .about_content {
    padding: 2rem 0rem;
  }

  .about_how_work {
    padding: 2rem 0rem;
  }

  .about_how_work:before {
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
  }

  .about_how_work:after {
    display: none;
  }

  .about_who_sec {
    padding: 2rem 0rem 0rem;
  }

  .about_who_sec:before {
    display: none;
  }

  .about_content {
    padding-bottom: 2rem;
  }

  .members_sec {
    padding: 2rem 0rem 5rem;
  }

  .members_sec:after {
    display: none;
  }

  .members_sec .slick-slider {
    padding-bottom: 0;
  }

  .how_curve {
    display: none;
  }

  .how_sec:before {
    display: none;
  }

  .how_sec:after {
    display: none;
  }

  .home_banner_block:before {
    display: none;
  }

  .contactinner {
    padding: 20px 30px 0px;
  }

  .bussinessSelect li .selectboxouter {
    padding: 5px 10px;
  }

  .loginform,
  .profilescreen,
  .whoamform {
    width: 100%;
  }

  section.loginouter {
    padding: 7rem 0 2rem;
  }

  .nextbtn {
    width: 150px;
  }

  .loginform h3 {
    font-size: 1.89rem;
  }

  .contactUs form {
    margin-bottom: 40px;
  }

  .mentor_modal .mentor_pic {
    max-width: 290px;
    margin: 0 0 30px;
  }

  .mail_outer {
    padding: 20px;
  }

  .mailbox {
    padding: 30px 20px;
  }

  .contact_info .locate {
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .contact_info {
    align-items: flex-end;
  }

  .contact_info .locate h4 {
    margin: 5px 0 0;
  }

  .sidebar_sec {
    display: none;
  }

  #sidebar_panel.sidebar_sec {
    display: block;
  }

  .studentformdashboard .form-group.position-relative span {
    padding: 13px 12px;
  }

  .mentor_btn {
    display: flex;
    justify-content: center;
    padding-top: 0;
    align-items: center;
  }

  .mentor_btn div {
    width: 90%;
  }

  .event_listing.verticle_grid figure img {
    width: 100%;
  }

  .dashboard_outer {
    height: calc(100% - 106px) !important;
  }

  .chatbodyclass .message-bar-head {
    height: calc(100%);
  }


  .chetboxouter .jumbotron_rightsec .ChatContent {
    height: 100%;
  }

  .dashboard_inner {
    min-height: initial;
    height: calc(100vh - 106px);
  }

  .scrollViewClassName {
    height: calc(100% - 40px) !important;
  }

  .chetboxouter .jumbotron_rightsec {
    min-height: 100% !important;
  }

  .dashboard_outer {
    padding-top: 106px;
  }

  .achievements_list li { 
    margin-bottom: 15px;
  }

  .home_banner_block .col-md-7,.home_banner_block .col-md-5 {
    flex: 0 0 100%;
    max-width: 100%;
}

/* .banner_content p {
    text-align: center;
} */

.caption_Secondary {
    text-align: left;
    margin-top: 20px;
    padding-top: 20px;
}
.achivment {
  padding: 20px 0 0 0;
}
  /* .achievements_list {
    justify-content: center;
  } */

  .banner_title br{display:none;}
  .achievements_list li:first-child {
    padding-left: 20px;
}

.achievements_list{margin-left:-20px;}

  /**=====faq=====**/
  .help_form_head h4 {
    font-size: 32px;
  }

  .help_search .form-control {
    padding: 0 !important;
  }

  .faq_page .nav.nav-tabs {
    padding: 20px 0;
  }

  .faq_page .nav.nav-tabs .nav-item {
    padding: 15px 24px;
    font-size: 16px;
  }

  .browse_by_topic h3 {
    margin-bottom: 18px;
  }

  .faq_head .linkpart ul li a {
    padding: 10px !important;
  }

  .linkpart ul li a figure img {
    max-height: 36px;
  }

  .linkpart ul li a figure {
    min-height: 40px;
  }

  .linkpart ul li a h6 {
    font-size: 16px;
  }

  .faq_head .linkpart {
    padding-bottom: 0;
  }

  .faq-tabs {
    padding: 20px 0;
  }

  .platform {
    flex-direction: row;
    justify-content: flex-start;
  }

  .message_icon {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .platform h5 {
    max-width: calc(100% - 100px);
    text-align: left;
  }

  .faq_tabs_main button {
    font-size: 16px;
    padding: 15px 15px 15px 50px;
  }

  .faq_tabs_main button:before {
    top: 16px;
  }

  .platform {
    margin-bottom: 10px;
  }

  .card_custom figure {
    margin: 0 0 10px -16px;
  }

  .resource_hub_grid {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .privacy_link img {
    max-width: 150px;
  }

  .app_direction a img {
    max-height: 30px;
  }

  figure.large_image img {
    max-width: 270px;
    margin: auto;
    display: block;
  }

  .large_title h3 {
    font-size: 24px !important;
  }

  .partner-community .content_inner .back_img {
    height: auto;
  }

  .community_page_inner {
    margin-top: -12rem;
  }

  .champinoned-powered {
    padding: 0 70px;
  }

  .registration_form_wrapper {
    padding: 3rem 0;
  }

  .semi-header strong {
    font-size: 32px;
  }

  .we-offer h3 {
    font-size: 20px;
  }

  .we-offer-point li {
    font-size: 16px;
  }

  .connect_with_us figcaption {
    font-size: 16px;
  }
  .parter-slider .slick-next {
    right: -20px;
}  .parter-slider .slick-prev {
  left: -20px;
}

.how_content {
  padding-top: 24px;
}
.achievements_list li{text-align:center;}

.communities_sec .slick-slider {
  padding-top: 24px;
}
.leader_sec.testimonial-slider {
  padding-top: 72px;
}
.newsletter_content p {
  padding-left: 10px;
}

.newsletter_icon img {
  max-height: 34px;
}
 
.leader_sec.testimonial-slider .TestimonialsSilderBlock  {
  padding: 0 6px;
}

.brand {
  text-align: center;
}

.diversity   .slick-list {
  margin: 0 -10px;
}
.communities_sec .slick-track{margin:0 -6px;}
.members_sec .slick-track{justify-content:start;}

.bannerShapeImg {
  max-width: 280px;
}

.banner-Cap h1 {
  font-size: 20px;
  margin: 10px 0;
}

.poweredBY {
  margin-bottom: 10px;
}

.bannerShapelogo {
  left: 62px;
  bottom: 20px;
}



}

@media (max-width: 575.98px) {
  .banner-Cap{max-width:100%;}

  .poweredBY span img {
    max-height: 20px;
}
  .igniteSection{background-image:url(../images/mobileBanner.png);
  background-size:cover;
  background-position:right;
  padding:15px;
  text-align:center;
  background-repeat:no-repeat;
  }
  .bannerShapelogo {position: relative;background: #fff;border-radius: 6px;overflow: hidden;bottom: 10px;right: 10px;left: auto;}

  .bannerShapeImg {
      position: absolute;
      left: auto;
      top: auto;
      bottom: 0;
      right: 0;
  }
  
  .bannerShapelogo img {
      max-height: 48px;
  }
  .banner-Cap{padding-top:0;}

  .brand_sec .brand img {
    max-width: 130px;
    max-height: 50px;
    margin: 0;
}.banner-Cap-outer {position: initial;transform: translate(0);}
 


  .achievements_list li {
    padding: 0 20px;
}

  .leader_sec .slick-list{margin:0;}

  .leader_sec .slick-list .slick-slide {
    padding: 0 ;
}

.leader_sec .slick-list .slick-slide {
  padding: 0 ;
}


.diversity  .slick-list .slick-slide{padding:0 6px;}


  .menteesCols{flex:0 0 100%; max-width:100%;}
  .dashboard_inner .mentor_img {
      height: auto;
  }

  .parter-slider .slick-next {
    right: -10px;
}  .parter-slider .slick-prev {
  left: -10px;
}

  .mentoring_banner_caption h1 {
    line-height: 1.2;
  }

  .extar_info_link ul {
    /* justify-content: center; */
    padding: 10px 0;
  }

  .extar_info_link li img {
    max-height: 36px;
  }

  .download_app_detail {
    top: 0;
  }

  .download_app_detail strong:not(.qr-wrapper strong) {
    font-size: 28px;
  }

  .leader_sec.testimonial-slider .slick-list {
    margin: 0;
}

  .download_app_detail {
    padding: 24px 0 0 0;
  }

  .download_app_section {
    padding: 0 0 64px 0;
    text-align: center;
  }
.app_screen{margin-top:24px;}
  .qr-wrapper {
    float: none;
    display: inline-block;
    margin-right: 10px;
  }


  .app_screen img {
    max-width: 280px;
    margin: auto;
  }

  .app_screen {
    text-align: center;
  }

  .app_store_link {
    padding: 10px 0;
  }

  .download_app_detail p {
    font-size: 18px;
  }

  .download_app_detail p br {
    display: none;
  }

  /* partnership-page-css */

  .collapse_toggle_inner {
    position: absolute;
    left: 20px;
    top: 13px;
  }


  .community_page_inner .container {
    padding: 2rem 2rem;
  }

  .partner-community {
    padding: 5.3rem 0 4rem;
  }

  .offer_content p span {
    font-size: 18px;
  }

  .offer_content h3 {
    font-size: 22px;
  }

  .offer_content p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 15px;
  }

  .we_offer ul li {
    font-size: 16px;
  }

  .offer_main {
    background-color: #fff;
    padding: 30px 0 0;
  }

  /* 18/8/22 */

  .why-reson ul {
    padding-left: 1.84rem;
  }

  .chat-sec h3 {
    font-size: 19px;
  }

  .exicted-right-panel ul::before,
  .exicted-sec .exicted-right-panel li::before,
  .exicted-right-panel ul::after {
    display: none;
  }

  .parter-slider .partner-img {
    max-width: 58%;
  }

  .why-reson .inner-partner {
    margin-bottom: 3rem;
  }

  .exicted-sec figure {
    max-width: 235px;
  }

  .chat-sec {
    margin-top: 6rem;
  }

  .why-reson ul:before,
  .why-reson ul:after {
    background-size: 19px;
  }

  .why-reson figure {
    max-width: 164px;
  }

  .why-reson ul li::before {
    left: -24px;
    font-size: 14px;
  }

  .exicted-sec .exicted-right-panel li+li {
    margin-top: 13px;
  }

  .exicted-sec .exicted-right-panel li p {
    font-size: 15px;
  }

  .exicted-right-panel ul {
    padding-left: 2rem;
  }

  /* 18/8/22 */


  /* partnership-page-css */

  .linkpart ul li a h6 {
    padding-bottom: 0;
  }

  .jumbotron_rightsec {
    padding: 20px 15px;
  }

  .linkpart ul li {
    min-height: 120px !important;

  }

  a.toggle_btn {
    top: 100px;
    width: 36px;
    height: 36px;
    font-size: 16px;
  }

  a.toggle_btn {
    top: 107px;
    width: 36px;
    height: 36px;
    font-size: 16px;
  }


  .linkpart ul li {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .dash_logo {
    margin-bottom: 10px;
  }

  .chetboxouter .jumbotron_rightsec .StickyFooter {
    left: 0;
    margin: 10px;
    width: calc(100% - 12px);
    bottom: 0px;
  }

  .dashboard_outer {
    padding-top: 120px;
  }

  .chatbodyclass .backbtn {
    font-size: 14px;
    width: 24px;
    height: 24px;
  }

  .meeting_form .custom-select {
    padding-right: 3.3rem;
  }

  .MessageBox .ChatMessage .RightBubble {
    min-width: 196px;
    margin-right: 0px;
  }

  .MessageBox .ChatMessage .LeftBubble {
    margin-left: 0px;
    min-width: 183px;
  }

  .name_info {
    padding-bottom: 0;
  }

  .mentro_details_leftbar {
    width: 8rem;
  }

  .mentro_details_sidebar {
    width: calc(100% - 8rem);
  }

  .date_list li:last-child {
    width: 100%;
  }

  .mentro_details .date_content {
    font-size: 1rem;
  }

  .mentor_box h3 {
    font-size: 1.5rem;
  }

  .dash_MentorList .col {
    width: 50%;
  }

  .dashboard_header .col-sm-6 {
    width: 50%;
  }

  .dash_logo img {
    max-width: 150px;
  }

  .DashHeader_right .user {
    font-size: 0;
    margin-right: 0;
  }

  .dashboard_sidebar {
    top: 74px;
    width: 280px;
  }

  .show_sidebar .toggle_btn_outer {
    left: 280px;
  }

  .toggle_btn_outer {
    top: 74px;
  }

  .dashboard_inner {
    padding: 40px 15px 20px;
  }

  .studentformdashboard span.uploadfile {
    top: 24px;
    padding: 15px 15px 14px;
  }

  .dropdownouter button::after {
    color: #0078ff;
    position: absolute;
    right: 20px;
    top: 48%;
    transform: translateY(-50%);
  }

  .dropdownouter button {
    padding-left: 0px;
    width: 100%;
    text-align: left;
    position: relative;
    border: 1px solid #ccc !important;
    padding: 5px 15px;
    font-family: "linotte-regularuploaded_file";
    height: 50px;
    margin-bottom: 15px;
  }

  .navigation .navbar {
    padding-left: 15px;
    padding-right: 15px;
  }

  .navigation.fixed-top nav {
    padding: 0.5rem 15px;
  }

  .loginform {
    padding: 20px;
  }

  .nextbtn {
    width: 120px;
  }

  .contactUs iframe {
    height: 150px;
  }

  .page_heading {
    margin-bottom: 2rem;
  }

  .outer_main {
    padding: 6rem 0 2rem;
  }

  .page_heading h2 {
    font-size: 1.8rem;
  }

  .page_heading h1 {
    font-size: 1.8rem;
  }

  .contactUs .subHeading {
    font-size: 2.07rem;
  }

  .mentor_modal .modal-content {
    padding: 3rem 0 2rem 0;
  }

  .mentor_modal .mentor_pic {
    margin: 0 0 15px;
  }

  .profile_btn .btn,
  .profile_btn .link_btn {
    font-size: 14px;
    padding: 10px;
  }

  .mentor_modal .mentor_name h3 {
    font-size: 24px;
  }

  .mentor_modal .mentor_name h5 {
    font-size: 15px;
  }

  .mentor_modal h2 {
    font-size: 25px;
    margin-bottom: 12px;
  }

  .contact_info {
    margin-top: 3.46rem;
  }

  .profile_btn .link_btn {
    margin-right: 10px;
  }

  .event_listing .list_info h3 a {
    font-size: 16px;
  }

  .date,
  .locationfix {
    font-size: 12px;
  }

  .event_listing.verticle_grid figure {
    margin-bottom: 5px;
  }

  .event_listing .list_info {
    padding: 10px;
  }

  .dashboard_inner {
    min-height: 0;
    min-height: initial;
    height: calc(100vh - 160px);
  }

  .dashboard_outer {
    height: calc(100% - 160px) !important;
  }



  /**=======faq=======**/

  .help_form_head h4 {
    font-size: 26px;
  }

  .help_form_head h1 {
    font-size: 26px;
  }

  .faq_page .nav.nav-tabs {
    padding: 15px 0;
  }

  .faq_page .nav.nav-tabs .nav-item {
    padding: 10px 10px;
    font-size: 15px;
    margin: 5px 0;
  }

  .browse_by_topic {
    padding: 10px 0;
  }

  .linkpart ul li a figure img {
    max-height: 32px;
  }

  .linkpart ul li a figure {
    min-height: 32px;
  }



  .faq_head .linkpart ul li a {
    padding: 20px 10px !important;
  }

  .faq_tabs_main button {
    padding: 12px 15px 12px 40px;
    font-size: 15px;
  }

  .faq_tabs_main .card-body {
    padding: 5px 20px 24px 20px;
  }

  .faq_text_content ul li,
  .faq_text_content p,
  .faq_text_content p strong,
  .faq_text_content ul li strong {
    font-size: 15px;
  }

  .help_form_head {
    padding: 10px 0 0 0;
  }

  .message_icon {
    font-size: 24px;
    flex: 0 0 58px;
    width: 58px;
    margin-right: 10px;
  }

  .platform h5 {
    font-size: 16px;
    max-width: calc(100% - 68px);
  }

  .card_custom figure {
    margin: 0 0 0 -16px;
  }

  .work_flow_list ul>li {
    padding-left: 0;
  }



  .large_title h3 strong {
    font-size: 46px;
    padding: 0px;
    margin-left: 0;
    float: left;
  }

  .large_title h3 {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .faq_text_content h3 {
    font-size: 16px;
  }

  .card_custom.relationship_grid ul li:last-child {
    padding-bottom: 0;
  }

  .resource_hub_grid {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .resource_hub_grid figure img {
    max-height: 50px;
  }

  .privacy_link img {
    max-width: 100px;
  }

  .app_direction a img {
    max-height: 30px;
  }

  .app_direction a img {
    max-height: 30px;
  }

  .faq_text_content a.btn {
    font-size: 12px;
    padding: 4px 10px;
  }

  .faq_text_content ul.stepsbox li {
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 32px;
    text-align: center;
  }

  .faq_text_content ul.stepsbox li:after {
    right: 0;
    left: 50%;
    bottom: -34px;
    top: auto;
    transform: rotate(90deg);
    background-position-y: center;
    margin-left: -12px;
  }

  .help_search {
    height: 44px;
    border-radius: 5px;
    padding: 10px;
  }

  .help_search .btn {
    padding: 11px;
  }


  .outer_main.faq_page_main:after {
    display: none;
  }

  .outer_main.faq_page_main {
    margin-bottom: 5px;
  }

  .faq-tabs {
    padding-bottom: 0;
  }

  .faq_text_content a.btn {
    font-size: 12px;
    padding: 4px 10px;
  }

  .work_flow_list ul li figure img {
    max-height: 32px;
    max-width: 32px;
  }

  .work_flow_list ul li h5 {
    max-width: calc(100% - 50px);
  }

  .work_flow_list ul li figure {
    flex: 0 0 46px;
    max-width: 46px;
    margin-bottom: 0;
  }

  .ans_column {
    padding: 10px 0;
    background: transparent;
  }

  .resource_hub_grid p {
    padding-bottom: 0;
  }

  .resource_hub_grid {
    padding: 10px;
  }

  .card_custom figure img {
    max-height: 60px;
  }


  .faq_text_content ul li {
    background-size: 14px;
    padding-left: 26px;
    background-position: 0 16px;
  }

  .community_page_inner {
    margin-top: -12rem;
  }

  .champinoned-powered {
    padding: 0 15px;
  }

  .champinoned-powered {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .champinoned-grid h6 {
    text-align: center;
  }

  .champinoned-grid {
    margin-bottom: 15px;
  }

  .semi-header strong {
    font-size: 26px;
    margin: 10px 0 20px 0 0;
  }

  .preregisterationFrom .loginform h3 {
    font-size: 22px;
  }

  .form_small_heading {
    font-size: 16px;
  }

  .radio_group.sm-column {
    flex-direction: column;
    text-align: left;
  }

  .radio_group.sm-column .custom_radio {
    margin: 7px 0;
  }

  .remember_me {
    padding: 10px 0;
  }

  .remember_me .custom_check {
    font-size: 15px;
  }

  .registration_form_wrapper .radio_group {
    margin: 0;
  }

  .registration_head .logo img {
    width: 220px;
  }

  .welcome-text {
    font-size: 18px;
  }

  .connect_with_us {
    padding: 15px;
  }

  .connect_with_us figure {
    flex: 0 0 44px;
    max-width: 44px;
    padding-right: 10px;
  }

  .offer_img {
    width: 84%;
  }

  .offer_content {
    padding: 15px;
  }

  .benifits_list .offer_content p {
    font-size: 16px;
  }

  .we_offer .d-flex {
    padding: 24px 0;
  }


  section.we_offer.partner_ship {
    padding-bottom: 54px;
  }

  .programs_are_driven {
    padding: 20px 0;
  }

  .members_sec .member_box.text-center .member_img {
    height: 240px !important;
}
.DOIDBannerContent h1 strong {
  font-size: 20px;
}

.DOIDBannerContent h1 small {
  font-size: 15px;
}

.DOIDBannerContent h1 {
  font-size: 18px;
}

.DoIdLogoWrapper img {
  max-height: 26px;
}

.DoIdLogoWrapper {
  min-height: 36px;
  display: inline-block;
  padding: 5px 10px;
}

}
 


@media (max-width: 479.98px) {

  ul.quicklinks li a{line-height:1.6;}

  .about_who_sec{margin-top:0;}

  section.about_how_work{margin:15px 0;}

  .diversity .slick-track {
    margin-left: -6px;
}

  .programs_are_driven_point li a span {
    width: 40px;
    height: 40px;
  }


  .programs_are_driven_point li a {
    padding: 10px 15px;
    margin-bottom: 10px;
  }




  .community_page_inner .container .row_c {
    flex-direction: column;
  }

  .linkpart ul li a h6 {
    padding-bottom: 0;
  }


  .loginform .userprofile span {
    display: block;
  }

  .loginform .userprofile span:last-child {
    padding-left: 0 !important;
  }

  .loginform .userprofile {
    padding-bottom: 10px !important;
  }

  .community_page_inner .justify-content-center {
    flex-grow: 1;
    margin-right: auto;
    width: auto;
    margin-top: 10px;
    margin-left: 0 !important;
  }

  .mentro_details_sidebar {
    width: 100%;
    padding-left: 0;
    padding-top: 1rem;
  }

  .date_Resulte {
    width: 100%;
    justify-content: space-between;
  }

  .dropedown_day {
    margin-left: inherit !important;
    margin-top: 0.8rem;
    width: 100%;
  }

  .mentro_details_leftbar {
    margin: 0 auto;
  }


  .studentformdashboard .form-group.position-relative span {
    padding: 10px 12px;
  }

  /*
	.banner_video {
		width: 20rem;
		height: 20rem;
	}
*/

  .quick_box {
    margin-right: auto;
  }

  span.uploadfile {
    padding: 16px 15px;
    width: 90px;
  }

  .contact_info {
    flex-wrap: wrap;
    justify-content: center;
  }

  .contact_info .locate {
    width: 50%;
    margin-bottom: 10px;
  }

  .mailbox h4 {
    font-size: 20px;
  }

  .mailbox img {
    margin: 20px auto;
    max-width: 80px;
  }

  .mail_outer {
    padding: 5px;
  }

  .mentor_btn a {
    font-size: 1rem;
    padding: 13px 10px;
    margin: 8px 5px;
  }

  .mentor_form .search input {
    padding: 12px 45px 12px 12px;
  }

  .form-control {
    padding: 12px;
  }

  .leader_box {
    width: 100%;
  }

  .leader_box img {
    width: 100%;
  }

  .secondaryTitle {
    font-size: 1.8rem;
    padding-right:100px;
}

.slick-prev:before, .slick-next:before {
  width: 12px;
  height: 12px;
}

.leader_sec .slick-prev{right:64px;}
.testimonials-slider-div p{font-size:15px; padding:24px 20px 64px 20px;}

  .achievements_list li span {
    padding: 5px 0;
    /* font-size: 20px; */
  }

  .sec-heading .title {
    font-size: 1.6rem;
}.newsletter_sec:before {
  background-size: 100%;
  top: -50px;
  background-repeat: no-repeat;
}

.download_app_section {
  padding: 0 0 24px 0;
 
}
.newsletter_sec{padding-bottom:24px;}

  .achievements_list li strong {
    font-size: 15px;
  }

  .community_page_inner {
    margin-top: -10rem;
  }

  .inner-partner {
    font-size: 1.8rem;
  }

  .igniteSection{text-align: left;}

  .DoIdLogoWrapper {
    margin-top: 4px;
}

.DoIdLogoWrapper img {
    max-height: 20px;
}

.DoIdLogoWrapper {
    min-height: 30px;
}

.powered-champianed {
    margin-top: 8px;
}

.DOIDBannerContent h1 {
    line-height: 1.1;
}

}

@media (max-width: 379.98px) {
  .date_list li {
    width: 50%;
  }

  .meeting_form .btn {
    min-width: 10rem;
    min-height: 3rem;
  }

  .community_page_inner {
    margin-top: -9rem;
  }


  .banner-Cap h1{font-size:20px;}
  .poweredBY strong{margin-bottom:5px; font-size:14px;}
}



.clearallbtn{
  min-width: 80px !important;
  line-height: 1;
  min-height: 36px;
  font-size: 14px;
  width: auto !important;
  margin-right: 5 !important;
}

