@font-face {
  font-family: "linotte-bolduploaded_file";
  src: url("../fonts/linotte-bold_400-webfont.woff2") format("woff2"),
    url("../fonts/linotte-bold_400-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "linotte-heavyuploaded_file";
  src: url("../fonts/linotte-heavy_400-webfont.woff2") format("woff2"),
    url("../fonts/linotte-heavy_400-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "linotte-regularuploaded_file";
  src: url("../fonts/linotte-regular_400-webfont.woff2") format("woff2"),
    url("../fonts/linotte-regular_400-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "linotte-semibolduploaded_file";
  src: url("../fonts/linotte-semibold_400-webfont.woff2") format("woff2"),
    url("../fonts/linotte-semibold_400-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: "linotte-regularuploaded_file";
  font-size: 13px;
}

body {
  font-family: "linotte-regularuploaded_file";
  font-size: 1.28rem;
  color: #353535;
  line-height: normal;
  position: relative;
}

body:after {
  /* content: "beta";
  position: fixed;
  width: 80px;
  height: 25px;
  background: #EE8E4A;
  top: 7px;
  left: -20px;
  text-align: center;
  font-size: 13px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 27px;
  transform:rotate(-45deg); */
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

/*add a shadow to the scrollbar here*/
::-webkit-scrollbar-track {
  box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

/*this is the little scrolly dealio in the bar*/
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 3px;
}

/*nobody needs this little scrollbar corner, I mean really, get rid of it haha*/
::-webkit-scrollbar-corner {
  display: none;
  height: 0px;
  width: 0px;
}

img {
  max-width: 100%;
  display: inline-block;
}

button,
input,
textarea,
select {
  font-family: "linotte-regularuploaded_file";
  opacity: 1;
  letter-spacing: 0.0143rem;
}

button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

ul,
li {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

a {
  display: inline-block;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  color: #dd2a2a;
}

a:hover,
a:focus {
  outline: inherit;
  text-decoration: none;
}

a:hover {
  color: #2e56bf;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "linotte-regularuploaded_file";
  line-height: normal;
  padding: 0px 0px;
  margin: 0px 0px;
}

section::after {
  display: block;
  clear: both;
  content: "";
}

.full_container {
  max-width: 1920px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0rem auto;
}

.form-control::-webkit-input-placeholder {
  color: #a8a8a8;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #a8a8a8;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #a8a8a8;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #a8a8a8;
  opacity: 1;
}

.form-control::placeholder {
  color: #a8a8a8;
  opacity: 1;
}

.logo {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  padding: 0;
}

.logo img {
  width: 15.42rem;
  display: inline-block;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.navigation {
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 9;
}

.navigation.fixed-top {
  width: 100%;
  position: fixed;
  top: 0%;
  -webkit-animation: slide-down 0.7s;
  animation: slide-down 0.7s;
  -webkit-box-shadow: 0px 0.071rem 0.71rem 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0.071rem 0.71rem 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  background: #fff;
}

.navbar-expand-lg {
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  padding: 2.14rem 0rem;
}

.navigation .navbar-nav .nav-link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  padding: 2px 8px;
  font-size: 1.38rem;
  color: #222222;
  font-family: "linotte-bolduploaded_file";
  position: relative;
}

.navigation .navbar-nav .nav-link::before {
  width: 2.14rem;
  height: 2px;
  background-color: #ef4343;
  position: absolute;
  bottom: 0%;
  left: 1rem;
  overflow: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  content: "";
}

.navigation .navbar-nav .nav-link:hover::before {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.navigation .navbar-nav .nav-link:hover {
  color: #ef4343;
}

.navigation .navbar-toggler {
  width: 40px;
  height: 35px;
  line-height: 0;
  color: #ffffff;
  border-color: #0078ff;
  background-color: transparent;
  padding: 0px 0px;
  margin: 0rem 0rem 0rem auto;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("../images/gird.svg");
  background-repeat: no-repeat;
  background-position: left center;
}

/* .navigation.fixed-top {
  width: 100%;
  position: fixed;
  top: 0%;
  animation: slide-down 0.7s;
  box-shadow: 8px 0px 28px 0px rgba(150, 145, 145, 0.3);
  z-index: 9;
  background: #fff;
} */
.navigation.fixed-top .navbar-expand-lg {
  padding: 0.5rem 15px;
}

@keyframes slide-down {
  0% {
    opacity: 1;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.btn {
  font-family: "linotte-semibolduploaded_file";
  font-size: 1.28rem;
  color: #fff;
  padding: 0.5rem 0.75rem;
  border-color: #ef4343;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  background: #ef4343;
}

.btn:hover {
  border-color: #2a6dfb;
  background-color: #2a6dfb;
  color: #fff;
}



.dropdown-toggle::after {
  margin-top: 9px;
}

.testinomials_img span.leader_title {
  float: right;
  margin-top: -48px;
}

.btn_login {
  background-color: #fff;
  border-color: #ef4343;
  color: #ef4343;
  min-width: 5.85rem;
  font-family: "linotte-semibolduploaded_file";
}

.btn_join {
  background-color: #2a6dfb;
  color: #fff;
  border-color: #2a6dfb;
  min-width: 10.14rem;
  font-family: "linotte-semibolduploaded_file";
}

.btn_join:hover {
  background: #fff;
  color: #2a6dfb;
}

.home_banner_block {
  padding-top: 10rem;
  position: relative;
  padding-bottom: 2rem;
}

.home_banner_block:before {
  width: 8.14rem;
  height: 40.21rem;
  background-image: url("../images/banner_curve.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  transform: translateY(-50%);
}

.home_banner_block:after {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  background-image: url("../images/curve.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right -13rem bottom;
  z-index: -1;
}

.leader_sec .title a {
  color: #222222;

}

.banner_title {
  color: #222222;
  font-size: 3.21rem;
  font-family: "linotte-bolduploaded_file";
}

.banner_form {
  position: relative;
  width: 35.71rem;
}

.banner_form .form-control {
  border-color: #cccede;
  color: #a8a8a8;
  font-size: 1.28rem;
  box-shadow: 0px 8px 28px 0px rgba(150, 145, 145, 0.1) !important;
  background: #fff;
  height: 4.5rem;
  font-family: "linotte-regularuploaded_file";
  border-radius: 0.71rem;
  padding-left: 1.14rem;
  padding-right: 11rem;
}

.banner_form .btn-signup {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  color: #fff;
  font-size: 1.57rem;
  font-family: "linotte-bolduploaded_file";
  border-radius: 0 0.71rem 0.71rem 0;
  min-width: 10.71rem;
}

.login_link {
  font-size: 1.28rem;
}

.banner_video {
  height: 39.85rem;
  width: 39.85rem;
  border-radius: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -5.14rem;
  position: relative;
  padding: 1rem;
}

.play_box {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
}

.play_box:before {
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  border-radius: 100%;
}

.btn_play {
  width: 6.42rem;
  height: 6.42rem;
  border-radius: 100%;
  border: 4px solid;
  border-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 2rem;
}

.testimonial-slider .slick-slide {
  padding: 0 6px;
}

.banner_video img {
  border-radius: 100%;
}

.sec-heading .title {
  padding-right: 100px;
}

.sec-heading .title {
  font-size: 2rem;
}

.title {
  color: #222222;
  font-size: 2.60rem;
  font-family: "linotte-bolduploaded_file";
}

.how_sec {
  /*background-image:url("../images/how_bg.png"); background-repeat: no-repeat; background-size:100%; background-position:center center;*/
  padding: 6rem 0rem 5rem 0;
  position: relative;
}

.how_sec:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 91.1%;
  width: 45%;
  background-color: #f9f9f9;
  z-index: -1;
  transform: skew(0deg, 4deg);
}

.how_sec:before {
  width: 5.92rem;
  height: 92%;
  background-image: url("../images/how_angel.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 100% 100%;
  position: absolute;
  left: 45%;
  top: 30px;
  content: "";
}

.how_curve {
  width: 5.92rem;
  height: 28.5rem;
  background-image: url("../images/how_curve.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  transform: translateY(-50%);
}

.box {
  padding-top: 2.14rem;
}

.icon_box {
  height: 6rem;
  width: 6rem;
  border-radius: 100%;
  border: 0.7rem solid #e9f0ff;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: crescendo 1.5s alternate infinite ease-in;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

/* @keyframes crescendo{
    0% {
        transform: scale(.8);
    }
    100% {
        transform: scale(1);
    }
} */

.descraption {
  width: calc(100% - 6rem);
  color: #222222;
  font-size: 1.85rem;
  font-family: "linotte-semibolduploaded_file";
  transition: all 0.5s ease-out;
  padding-left: 2.85rem;
}

.how_content a:hover .descraption {
  color: #ef4343;
}

.how_content a:hover .icon_box {
  border-color: #2a6dfb;
}

/*.leader_box{ width:15.57rem; margin:0 auto;}*/
.leader_sec {
  padding: 7.85rem 0rem 5.85rem 0;
}

.leader_box {
  width: 20rem; 
  position: relative;
  background-color: #F3F3F3;
  padding:3px;
  border-radius:6px;
  overflow: hidden;
}

.leader_img { 
  margin-bottom: 1rem;
  position: relative;
  border-radius: 0.71rem 0.71rem 0 0;
  overflow: hidden;
}

 

.leader_title {
  color: #fff;
  font-family: "linotte-regularuploaded_file";
  font-size: 1.42rem;
  margin-top: 0;
  display: block;
  text-align: left;
  padding: 0 15px;
  position: absolute;
  z-index: 2;
  bottom: 10px;
}
.leader_img:after {
  content: "";
  background: linear-gradient(0, #000000ad, transparent);
  position: absolute;
  top: auto;
  left: 0px;
  right: 0px;
  height: 90px;
  bottom: 0px;
  border-radius: 0;
  z-index: 1;
}
.brand_sec .slick-track {
  display: flex;
  align-items: center;
}

.leader_info {
  background-color: rgba(239, 67, 67, 0.8);
  border-radius: 0.71rem;
  padding: 1.42rem;
  min-width: 19.71rem;
  position: absolute;
  left: -24px;
  top: -110px;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  box-shadow: 0px 0px 24px 0px rgba(230, 230, 230, 0.1);
}

.leader_info:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(239, 67, 67, 0.8);
  margin: 0 auto;
}

.leader_info span {
  font-size: 1.14rem;
  color: #fff;
  display: block;
  font-family: "linotte-regularuploaded_file";
  padding-bottom: 0.5rem;
}

.leader_info span:last-child {
  padding-bottom: 0;
}

.leader_info .name {
  font-size: 1.42rem;
  font-family: "linotte-bolduploaded_file";
}

.leader_box:hover .leader_info {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.brand_sec {
  background-color: #fff;
  padding: 5rem 0rem;
}

.brand img {
  margin: 0 auto;
}

.space-bottom {
  padding-bottom: 5rem;
}

/*
.student_sec{ padding:4rem 0rem 9rem;}
.video_box{text-align:center; opacity:0.5;  }
.video_box img{-webkit-transform: scale(0.7); transform: scale(0.7); }
.video_box .play_box{ border:3px solid transparent;}
.video_box .play_box:before{ display:none;}
.video_box .btn_play{ border-color:#f02600; color:#f02600; height:4.35rem; width:4.35rem; font-size:1.5rem;}

.info{ opacity:0;}
.info span{ font-size:1.42rem; color:#222222; font-family: 'linotte-bolduploaded_file';}
.info small{ color:#7f7f7f; font-size:1.14rem; }
*/

.slick-prev,
.slick-next {
  margin-top: -10px;
}

.slick-slide.slick-current .video_box {
  opacity: 1;
}

.slick-slide.slick-current .play_box {
  border: 3px solid #ecedf3;
  margin: 0;
}

.slick-slide.slick-current .video_box img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.slick-slide.slick-current .info {
  opacity: 1;
}

.slick-prev:before,
.slick-next:before {
  color: #bababa;
  font-size: 3rem;
  font-family: "Font Awesome 5 free";
  font-weight: 900;
}

.slick-prev:before {
  content: "\f053";
}

.slick-next:before {
  content: "\f054";
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  color: #ef4343;
}

.leader_sec .slick-list {
  padding-top:32px;  
  margin:0 -22px;
}
.leader_sec .slick-list .slick-slide {
  padding: 0 15px;
}

.leader_sec .slick-prev, 
.leader_sec .slick-next {
  top: 60%;
}

.myaccourdin .card-body ul>li {
  list-style: disc;
  line-height: 27px;
}

.myaccourdin .card-body ul>li ul>li {
  list-style: circle;
  margin-left: 40px;
}

.newsletter_sec {
  background-image: url("../images/buildingShape.png");
  background-position: center bottom -60px;
  padding: 0 0rem 4rem;
  background-size: auto;
  background-repeat: no-repeat;
  background-color: #f9f9f9;
  position: relative;
  margin-top: 24px;
}

.newsletter_sec:before{
  background-image: url("../images/buildingColor.png");
  content: "";
  width: 100%;
  height: 100px;  
  position: absolute;
  top: -94px;
}



.start-building:before {
  width: 100%;
  height: 84px;
  position: absolute;
  left: 0;
  top: 0px;
  content: "";
  background-image: url("../images/building_curve.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0;
}

.start-building .btn {
  font-size: 1.57rem;
  min-width: 14.28rem;
  margin-top: 1.42rem;
}

.start-building .title {
  font-size: 2.2rem;

}

.outer_main {
  background: linear-gradient(190deg, #f9f9f9 35%, #fff 35%);
}

.page_content_inner {
  background: #fff;
  padding: 1.5rem 1rem;
}

.about_content_mian {
  /* padding-top: 12rem; */
  background: linear-gradient(190deg, #f9f9f9 47%, #fff 46%);
}

.about_content_mian .about_content {
  padding: 12rem 0rem 1rem;
}

.cms_content {
  padding: 12rem 0rem 4rem;
}

.cms_content p,
ul>li,
ol>li {
  font-size: 1.4rem;
  color: #808080;
  line-height: 30px;
}


.about_content p {
  color: #626262;
  font-size: 1.28rem;
}

/*
.about_img{ padding:0.71rem; box-shadow:8px 0px 28px 0px rgba(150, 145, 145, 0.10); width:32.85rem;}
.about_img:before{position:absolute; left:5px; top:-2px; content:''; height:90%; width:1px; background:#ef4343;}
.about_img:after{position:absolute; left:0; top:0; width:70%; height:1px; background:#ef4343; content:'';}
*/

.about_content_sec .title {
  font-size: 2.85rem;
}

.about_content p {
  font-size: 1.4rem;
  line-height: 30px;
}

.about_how_work {
  min-height: 60rem;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 22rem 0rem;
  max-width: 2560px;
  margin: auto;
}

.about_how_work:after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  background-image: url("../images/pattern-left.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
  width: 75px;
  height: 383px;
  top: 42%;
}

.about_how_work:before {
  position: absolute;
  right: -150px;
  top: 160px;
  content: "";
  width: 81%;
  height: 715px;
  background: #f9f9f9;
  transform: skew(23deg, 6deg);
}

.how_left {
  position: inherit;
}

.how_img {
  position: absolute;
  left: 0px;
  top: 0;
  width: 42%;
  text-align: right;
}

.how_img img {
  width: 100%;
}

.how_list li .number {
  height: 6rem;
  width: 6rem;
  border-radius: 100%;
  border: .7rem solid #e9f0ff;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: crescendo 1.5s ease-in infinite alternate;
  animation: crescendo 1.5s ease-in infinite alternate;
  padding: 0 10px;
  flex: 0 0 6rem;
}

.how_list li {
  align-items: center;
}

.how_list li .number img {
  width: 32px;
}

.about_content p {
  padding-top: 1rem;
}

.how_list li {
  display: flex;
  padding-top: 2.14rem;
}

.how_list li .number,
.how_list li .heading {
  font-size: 1.71rem;
  color: #0078ff;
  font-family: "linotte-bolduploaded_file";
}

.how_list li p {
  font-size: 1.28rem;
}

.btn_process {
  font-family: "linotte-semibolduploaded_file";
  min-height: 42px;
  font-size: 1.28rem;
  color: #ef4343;
  background: #fff;
  min-width: 23.5rem;
  display: inline-flex;
  align-items: center;
}

.about_who_sec {
  position: relative;
  padding: 0rem 0rem 14rem;
}

.about_who_sec:before {
  position: absolute;
  left: 0;
  top: 0px;
  content: "";
  background-image: url("../images/pattern-right.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  width: 89px;
  height: 502px;
}

.about_who_sec .sub_lead {
  font-size: 1.71rem;
  font-family: "linotte-bolduploaded_file";
}

.members_sec {
  position: relative;
  padding-bottom: 2rem;
}

.members_sec:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background: #f9f9f9;
  width: 100%;
  height: 50%;
  z-index: -1;
}

.members_sec:after {
  width: 100%;
  height: 174px;
  position: absolute;
  left: 0;
  top: -174px;
  content: "";
  background-image: url("../images/member_curve.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0;
}

.member_box img {
  border: 4px solid #fff;
  border-radius: 0.71rem;
  display: inline-block !important;
}

.member_box .name {
  color: #0078ff;
  font-family: "linotte-regularuploaded_file";
  font-size: 1.42rem;
}

.members_sec .slick-slider {
  padding-bottom: 10rem;
}

.members_sec .slick-prev:before {
  content: "\f060";
  font-size: 1.5rem;
}

.members_sec .slick-next:before {
  content: "\f061";
  font-size: 1.5rem;
}

.members_sec .slick-prev,
.members_sec .slick-next {
  bottom: 0px;
  top: inherit;
}

.members_sec .slick-prev {
  left: 47%;
}

.members_sec .slick-next {
  right: 47%;
}

.members_sec .slick-list .slick-track .slick-slide:nth-child(odd) {
  margin-top: 4.28rem;
}

.who_box {
  position: relative;
  margin-bottom: 1.5rem;
}

.who_info {
  background-color: rgba(239, 67, 67, 0.8);
  border-radius: 0.71rem;
  padding: 1.42rem;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  box-shadow: 0px 0px 24px 0px rgba(230, 230, 230, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.who_info span {
  font-size: 1.14rem;
  color: #fff;
  display: block;
  width: 100%;
  font-family: "linotte-regularuploaded_file";
  padding-bottom: 0.5rem;
  text-align: center;
}

.who_info span:last-child {
  padding-bottom: 0;
}

.who_info .name {
  font-size: 1.42rem;
  font-family: "linotte-bolduploaded_file";
}

.who_box:hover .who_info {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.boardmembers:hover .who_info {
  -webkit-transform: scale(1);
  transform: scale(1);
}

footer {
  border-top: 2px solid #f1f1f1;
  padding: 2.28rem 0rem 0;
}

.footerleft img {
  margin: 15px 0px;
}

.footerleft p {
  font-size: 1.14rem;
  color: #222222;
}

.footertop h3 {
  font-size: 1.42rem;
  color: #ef4343;
  margin-bottom: 1.07rem;
  font-family: "linotte-bolduploaded_file";
}

/* .quick_box{ max-width:80%; margin-left:auto;} */

ul.quicklinks {
  padding: 0px;
  margin: 0px;
}

ul.quicklinks li {
  list-style: none;
  width: 49%;
  display: inline-block;
  vertical-align: middle;
}

ul.quicklinks li a {
  color: #222222;
  margin-bottom: 0.71rem;
  display: block;
  text-decoration: none;
  font-size: 1.17rem;
}

ul.quicklinks li a:hover {
  color: #ef4343;
}

ul.sociallink {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: flex-end;
  margin-bottom:20px;
}

ul.sociallink li {
  display: inline-block;
  line-height:1;
}

ul.sociallink li a {
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: flex-start; */
    font-size: 25px;
    color: #959595;
    margin: 0 8px;
    line-height: 1;
    display:inline-block;
}

ul.sociallink li a:hover {
  color: #ef4343;
  text-decoration: none;
}

.copyright {
  padding: 15px 0px;
  text-align: center;
  background: #f9f9f9;
  color: #626262;
  margin-top: 15px;
}

.copyright p {
  margin: 0px;
  color: #626262;
  font-size: 1.13rem;
}

/***Login css start****/
/*
.footerleft p, ul.quicklinks li a{
    font-family: 'linotte-semibolduploaded_file';
}
*/
input,
select,
textarea {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #cccede !important;
}

.loginlogo img {
  width: 15.42rem;
  display: inline-block;
  transition: all 0.5s ease-out;
}

section.loginouter {
  padding: 10rem 0;
}

.logininnerouter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginform {
  background: #f9f9f9;
  padding: 50px;
  width: 50%;
  margin: 0 auto;
  border-radius: 15px;
}

.forgotPassword {
  width: 100%;
}

.loginform h3 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.85rem;
  font-family: "linotte-bolduploaded_file";
}

.loginform h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.85rem;
  font-family: "linotte-bolduploaded_file";
}

.loginform .form-control {
  min-height: 50px;
  font-size: 1.2rem;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: #a8a8a8;
}

.loginform .form-control.custom-select {
  background-image: url(../images/selectarrow.png);
  background-size: 15px;
}

.loginsubmit {
  font-size: 1.57rem;
  border-radius: 10px;
}

.studentsteps .loginform {
  width: 100%;
}

.steps_signup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.steps_signup>div {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.steps_signup>div .stepcount {
  background: #dfe3e5;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: transparent;
  position: relative;
}

.steps_signup>div .stepcount.active {
  background: #2a6dfb;
  color: #fff;
  z-index: 1;
  width: 25px;
  height: 25px;
}

.steps_signup>div .stepcount::before {
  content: "";
  position: absolute;
  width: 74px;
  height: 1px;
  background: #dfe3e5;
  right: 20px;
}

.steps_signup>div .stepcount:first-child::before {
  display: none;
}

.steps_signup>div .stepcount.active::before {
  background: #2a6dfb;
}

.profilescreen {
  width: 90%;
  margin: 50px auto 30px;
  background: #fff;
  padding: 30px 15px 5px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px 0px rgba(150, 145, 145, 0.1);
}

.profilescreen h4 {
  font-size: 1.50rem;
  font-family: "linotte-bolduploaded_file";
}

.userprofile {
  padding: 50px 0px 40px;
}

span.inputfileouter {
  position: relative;
  display: inline-block;
  border: 1px solid #0078ff;
  border-radius: 5px;
  padding: 12px 25px;
  color: #0078ff;
  font-size: 1.24rem;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.5s ease-out;
}

span.inputfileouter input[type="file"] {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

span.inputfileouter:hover {
  background: #2a6dfb;
  color: #fff;
}

.userprofile img {
  /* width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover; */
}

.whoamform {
  width: 50%;
  margin: 20px auto;
}

.whoamform h5 {
  font-size: 1.28rem;
  font-family: "linotte-bolduploaded_file";
}

.whoamform form p {
  font-size: 1.24rem;
  font-family: "linotte-regularuploaded_file";
  color: #000;
}

.whoamform .custom_radio {
  color: #a8a8a8;
}

.custom_radio {
  display: inline-block;
  position: relative;
  cursor: pointer;
  line-height: 22px;
  position: relative;
  padding-left: 30px;
  margin: 5px 8px;
}

.custom_radio input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 2;
}

.custom_radio .radio_indicator {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #cccccc;
  position: absolute;
  top: 0px;
  left: 0px;
}

.custom_radio input:checked+.radio_indicator:before {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #0078ff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -6px 0px 0px -6px;
  content: "";
}

.form-group.position-relative span {
  position: absolute;
  background: #cccede;
  color: #fff;
  padding: 13px 12px;
  border-radius: 9px 0px 0px 10px;
}

.form-group.position-relative span i {
  background: #0078ff;
  padding: 6px;
  border-radius: 2px;
  color: #fff;
}

.proficiency h4 {
  font-size: 1.23rem;
}

.form-group.position-relative .form-control {
  padding-left: 65px;
}

.nextbtn {
  width: 150px;
}

.profilescreen p {
  color: #a8a8a8;
}

.businessboxouter {
  margin: 50px auto;
  width: 90%;
}

.businessboxouter h5 {
  font-size: 1.385rem;
  font-family: "linotte-bolduploaded_file";
  margin-bottom: 15px;
}

.businessboxouter h5 {
  font-size: 1.585rem;
  font-family: "linotte-bolduploaded_file";
  margin-bottom: 15px;
}

.bussinessSelect li,
.businessboxouter h5 {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}

.bussinessSelect li .selectboxouter {
  background: transparent;
  border: 1px solid #0078ff;
  border-radius: 5px;
  padding: 10px 20px;
  color: #2a6dfb;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.bussinessSelect li .selectboxouter:hover {
  background: #2a6dfb;
  color: #fff;
}

.bussinessSelect li input[type="checkbox"] {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  opacity: 0;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

.bussinessSelect li input[type="checkbox"]:checked+.selectboxouter {
  background: #2a6dfb;
  color: #fff;
}

.businessboxouter h5 .selectboxouter {
  background: transparent;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 10px 20px;
  color: #000000;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.businessboxouter h5 .selectboxouter:hover {
  background: #000000;
  color: #fff;
}

.businessboxouter h5 input[type="checkbox"] {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  opacity: 0;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

.businessboxouter h5 input[type="checkbox"]:checked+.selectboxouter {
  background: #dd2a2a;
  color: #fff;
  border: 1px solid #dd2a2a;
}

.businessboxouter>div {
  margin-bottom: 20px;
}

.addown span {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #2a6dfb;
  color: #2a6dfb;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.1rem;
  cursor: pointer;
}

.addown {
  position: relative;
}

.loginsubmit.outline {
  background: transparent;
  color: #ef4343;
}

.loginsubmit.outline:hover {
  background: #2a6dfb;
  color: #fff;
}

.proficiency .custom_check .check_indicator,
.termsconditions .custom_check .check_indicator {
  width: 24px;
  height: 24px;
}

.proficiency .custom_check input:checked+.check_indicator:before,
.termsconditions .custom_check input:checked+.check_indicator:before {
  width: 8px !important;
  height: 14px !important;
  left: 7px !important;
}

.proficiency .custom_check,
.termsconditions .custom_check {
  line-height: 24px;
}

.inputfileupload {
  position: relative;
}

span.uploadfile {
  position: absolute;
  right: 0px;
  top: 0px;
  background: #efefef;
  color: #a8a8a8;
  padding: 18px 25px;
  width: 110px;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
}

.loginsubmit {
  min-height: 55px;
}

.contactinner {
  background: #fff;
  padding: 80px 30px;
}

.contactboxoutertab {
  background: linear-gradient(190deg, #f9f9f9 45%, #fff 35%);
}

span.uploadfile input[type="file"] {
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 100%;
  cursor: pointer;
}

.faqouter .card {
  margin-bottom: 10px;
  position: relative;
}

.faqouter .card-header {
  padding: 0px;
  background: transparent;
  border: 0px;
}

.faqouter .card-header button.btn {
  width: 100%;
  border-radius: 0px;
  text-align: left;
  background: transparent;
  border: 0px;
  color: #333;
  text-decoration: none;
  min-height: 45px;
  padding: 10px 50px 10px;
  box-shadow: none !important;
  font-size: 1.4rem;
}

.faqouter .card {
  border: 1px solid #eaeaea !important;
  padding: 9px 0px 9px;
}

.faqouter .card-body {
  padding: 0px 50px 15px;
  color: #808080;
  font-size: 1.384rem;
}


.faqouter .card-header button.btn.faq_open::before {
  color: #ed574e;
  position: absolute;
  z-index: 1;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f067";
  top: 9px;
  left: 15px;
}

.faqouter .card-header button.btn::before {
  color: #ed574e;
  position: absolute;
  z-index: 10;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f068";
  top: 19px;
  left: 15px;
}

.formError {
  border-color: #d01818 !important;
}

.formError+span {
  color: #d01818 !important;
  font-size: 1rem;
}

/* Raju css end */

.outer_main {
  padding: 12.3rem 0 4rem;
}

.page_heading {
  margin-bottom: 7rem;
}

.page_heading h2 {
  color: #222222;
  font-size: 2rem;
  font-family: "linotte-bolduploaded_file";
}

.page_heading h1 {
  color: #222222;
  font-size: 2rem;
  font-family: "linotte-bolduploaded_file";
}

.form-control {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(223, 223, 223);
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 6px 0px rgba(112, 112, 112, 0.15);
  font-size: 14px;
  font-family: "linotte-regularuploaded_file";
  color: #bcbcbc;
  height: auto;
  padding: 15px;
}

.mentor_form .search button {
  position: absolute;
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  right: 20px;
  top: 10px;
}

/*	Sidebar Css 	*/

.link {
  color: #ef4343;
}

.sidebar_heading {
  border-bottom: 1px solid #eaeaea;
}

.sidebar {
  border: 1px solid #eaeaea;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
}

.sidebar_heading h3 a {
  font-size: 16px;
  font-family: "linotte-regularuploaded_file";
}

.sidebar_heading h3 {
  color: #222222;
  font-size: 20px;
  font-family: "linotte-bolduploaded_file";
}

.sidebar .seprator {
  padding: 12px;
}

.sidebar .seprator+.seprator {
  border-top: 1px solid #eaeaea;
}

.sidebar .seprator h6 {
  color: #222222;
  font-size: 16px;
  font-family: "linotte-bolduploaded_file";
  margin-bottom: 12px;
}

.sidebar .seprator li+li {
  margin-top: 15px;
}

.custom_check {
  color: #626262;
  font-size: 16px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  line-height: 22px;
  padding-left: 30px;
}

.profilescreen .custom_check input {
  width: 24px;
}

.custom_check input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 2;
}

.custom_check .check_indicator {
  height: 16px;
  width: 16px;
  position: absolute;
  top: 3px;
  left: 0px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 0px;
}

.custom_check input:checked+.check_indicator:before {
  width: 5px;
  height: 10px;
  position: absolute;
  left: 5px;
  top: 1px;
  border: solid #fff;
  border-width: 0px 2px 2px 0px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
}

.custom_check input:checked+.check_indicator {
  background-color: #0078ff;
}

.join-bt-deactive {
  pointer-events: none;
  cursor: default;
  background-color: #da8b8b !important;
}
/* 
.brand_sec .brand {
  background: #fefefe;
  padding: 15px;
  margin:0 8px;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
} */

.brand_sec .slick-slide { 
  padding:5px 0 10px 0;
}

.brand_sec .brand img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.mentor_list .outer {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(234, 234, 234);
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 23px;
  height:calc(100% - 23px);
  transition: 0.4s ease all;
  overflow: hidden;
}
.mentor_info>div span,.mentor_info>span {
  min-height: 20px;
  display: block;
}
.mentor_info h3 {
  color: #0078ff;
  font-size: 1.53rem;
  font-family: "linotte-semibolduploaded_file";
  margin-bottom: 5px;
}

.mentor_info p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mentor_info h6 {
  color: #222222;
  font-size: 1.23rem;
  font-family: "linotte-semibolduploaded_file";
  margin-bottom: 5px;
}

.mentor_info h6 p {
  font-size: 1.23rem;
  color: 626262;
  font-family: "linotte-regularuploaded_file";
  margin: 0;
}

.mentor_info {
  padding: 10px;
}

.mentor_btn {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 75px;
  transition: 0.4s ease all;
  opacity: 0;
  visibility: hidden;
}

.mentor_btn a {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.502);
  font-size: 1.23rem;
  font-family: "linotte-semibolduploaded_file";
  color: #fff;
  display: block;
  text-align: center;
  padding: 12px;
  margin: 8px 13px;
}

.mentor_btn a:hover {
  background-color: #ef4343;
}

.mentor_list .outer:hover .mentor_btn {
  top: 0;
  opacity: 1;
  visibility: visible;
}

/* Mentor Modal */

.mentor_modal .close {
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  opacity: 1;
  position: absolute;
  right: 15px;
  top: 15px;
}

.mentor_modal .modal-content {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  padding: 3.4rem;
}

.mentor_modal .mentor_pic {
  border: 1px solid rgb(234, 234, 234);
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  padding: 5px;position: relative;
}

.mentor_modal .mentor_name {
  padding: 1.53rem;
}

.mentor_modal .mentor_name h3 {
  color: #0078ff;
  font-size: 30px;
  font-family: "linotte-semibolduploaded_file";
}

.mentor_modal .mentor_name h5 {
  color: #222222;
  font-size: 18px;
  font-family: "linotte-regularuploaded_file";
}

.mentor_modal .mentor_name h5 span {
  display: block;
  font-family: "linotte-bolduploaded_file";
  margin: 5px 0;
}

.mentor_modal h2 {
  color: #222222;
  font-size: 28px;
  font-family: "linotte-semibolduploaded_file";
  margin-bottom: 18px;
}

.mentor_modal p {
  color: #626262;
  font-size: 16px;
  line-height: 24px;
}

.profile_btn .link_btn {
  border: 1px solid rgb(31, 96, 191);
  font-family: "linotte-semibolduploaded_file";
  color: #1f60bf;
  font-size: 18px;
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  padding: 12px 15px;
  display: inline-flex;
  align-items: center;
}

.profile_btn .link_btn:hover {
  background: #1f60bf;
  color: #fff;
}

.profile_btn {
  display: flex;
}

.profile_btn .btn {
  padding: 12px 15px;
  margin-left: 12px;
}

/*EMAIL VARIFICATION*/

.mail_outer {
  max-width: 1000px;
  margin: 0 auto;
  background: #f9f9f9;
  padding: 100px;
  width: 100%;
}

.mailbox {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 10px 0px rgba(150, 145, 145, 0.1);
  padding: 50px 0;
}

.mailbox h4 {
  color: #222222;
  font-size: 22px;
  font-family: "linotte-bolduploaded_file";
  margin-bottom: 6px;
}

.mailbox h6 {
  color: #a8a8a8;
  font-size: 16px;
}

.mailbox img {
  margin: 40px auto;
}

.mailbox p {
  color: #222222;
  font-size: 16px;
  max-width: 540px;
  margin: 0 auto;
}

/*===== CONTACT US ========*/

.contactUs .subHeading {
  font-size: 3.07rem;
  font-family: "linotte-bolduploaded_file";
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 12px;
}

.contactUs .subHeading:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background-color: rgb(238, 73, 77);
  width: 166px;
  height: 2px;
}

.contactUs form {
  margin-top: 1.92rem;
}

.contactUs form .form-control {
  padding: 12px 15px;
  border-radius: 10px;
}

.contactUs form textarea {
  min-height: 14.61rem;
}

.contactUs form .btn {
  font-size: 1.69rem;
  padding: 8px 3.84rem;
  border-radius: 0.61rem;
}

.contactUs iframe {
  max-width: 426px;
  margin: 0 auto;
  position: relative;
  display: block;
  box-shadow: 0px 0px 70px 5px rgba(0, 0, 0, 0.1);
  border: 0;
}

.contactUs .map:before {
  background-color: #f9f9f9;
  position: absolute;
  content: "";
  right: 0;
  top: -40px;
  width: 50%;
  height: 80%;
}

.contact_info {
  margin-top: 6.46rem;
  display: flex;
  justify-content: space-between;
}

.contact_info .locate {
  display: flex;
  align-items: center;
}

.contact_info .locate h4 {
  margin: 0 0 0 1.15rem;
  color: #222222;
  font-size: 1.69rem;
  font-family: "linotte-semibolduploaded_file";
}

.contact_info .locate h4 span {
  color: #828282;
  font-size: 16px;
  margin-top: 5px;
  display: block;
  font-family: "linotte-regularuploaded_file";
}

.mentro_details {
  border: 1px solid rgb(234, 234, 234);
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  padding: 1.28rem;
  margin: 0;
}

.mentro_details:last-child {
  margin-bottom: 0;
}

.mentro_details_leftbar {
  width: 14rem;
}

.mentro_details_leftbar .mentor_pic img {
  border-radius: 3px;
}

.mentro_details_sidebar {
  width: calc(100% - 14rem);
  padding-left: 1rem;
}

.mentro_details .btn {
  font-size: 16px;
  padding: 8px 15px;
}

.mentro_details .btn_red {
  border-color: #ef4343;
  color: #ef4343;
}

.mentro_details .btn_red:hover {
  background-color: #ef4343;
  color: #fff;
  border-color: #ef4343;
}

.mentro_details h3 {
  font-size: 1.28rem !important;
}

.mentro_details .name_info h4 {
  color: #0078ff;
  font-size: 1.71rem;
  font-family: "linotte-semibolduploaded_file";
}

.mentro_details .name_info h5 {
  color: #626262;
  font-size: 1.14rem;
  font-family: "linotte-semibolduploaded_file";
}

.mentro_details .name_info span {
  color: #222222;
  padding-bottom: 0.2rem;
}

.date_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mentorPending {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.mentro_details .date_icon {
  width: 2.85rem;
  height: 2.85rem;
  border-radius: 100%;
  background-color: #f1f6fc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mentro_details .date_content {
  font-size: 1.14rem;
  color: #626262;
  width: calc(100% - 2.85rem);
}

.mentro_details .date_content .date {
  color: #222222;
  font-family: "linotte-semibolduploaded_file";
}

.mentro_details .color_red {
  color: #ef4343 !important;
}

.schedule_popup .modal-content {
  max-width: 785px;
  margin: 0 auto;
  padding: 1.85rem;
}

.meeting_form .form-group {
  margin-bottom: 1.53rem;
  position: relative;
}

.meeting_form .form-label {
  font-size: 1.23rem;
  color: #222222;
}

.meeting_form .form-control {
  font-size: 1.23rem;
  color: #a8a8a8;
  border-radius: 0.71rem;
  height: 3.84rem;
  padding: 0.375rem 0.75rem;
}

.meeting_form .meet_user_title {
  font-size: 1.38rem;
  color: #222222;
  font-family: "linotte-bolduploaded_file";
}

.meeting_form .meet_user {
  color: #626262;
  font-size: 1.38rem;
}

.meeting_form .meet_user img {
  width: 4.92rem;
  height: 4.92rem;
  border-radius: 100%;
  border: 2px solid #e1e1e1;
  margin-right: 1rem;
}

.custom-select {
  background-image: url(../images/custom_select.svg);
  background-size: 1rem;
  background-position: right 1.5rem center;
}

.meeting_form .incrase_div {
  position: absolute;
  right: 20px;
  top: 50%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.meeting_form .incrase_btn {
  display: block;
  line-height: 0;
  width: 13px;
}

.meeting_form .incrase_btn.up {
  transform: rotate(180deg);
}

.meeting_form .btn {
  min-width: 11.53rem;
  min-height: 3.84rem;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 0.71rem;
  font-size: 1.69rem;
}

.meeting_form .color_black {
  color: #222222 !important;
}

.meeting_form .color_black::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #222222;
}

.meeting_form .color_black::-moz-placeholder {
  /* Firefox 19+ */
  color: #222222;
}

.meeting_form .color_black:-ms-input-placeholder {
  /* IE 10+ */
  color: #222222;
}

.meeting_form .color_black:-moz-placeholder {
  /* Firefox 18- */
  color: #222222;
}

.btn_cancel {
  background-color: #fff;
  color: #ef4343;
}

.mentor_box .Scheduled_box h3 {
  font-size: 1.71rem;
  font-family: "linotte-semibolduploaded_file";
}

.Scheduled_box .table {
  box-shadow: 14px 0px 25px 0px rgba(236, 236, 236, 0.35);
}

.Scheduled_box .table th {
  border-bottom: 0;
  font-size: 1.076rem;
  font-weight: normal;
  color: #0078ff;
}

.Scheduled_box .table td {
  font-size: 1.076rem;
}

.dropedown_day .custom-select {
  min-width: 9.07rem;
  height: 2.76rem;
  border-color: #ededed !important;
  color: #666666;
  font-size: 1.07rem;
}

.date_Resulte {
  border: 1px solid #ededed;
  font-size: 1.07rem;
  color: #666666;
  height: 3rem;
  border-radius: 0.35rem;
  overflow: hidden;
}

.date_Resulte .btn_next {
  width: 2.64rem;
  height: 100%;
  background-color: #e7e7ea;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.14rem;
}

.timing_box {
  border: 1px solid #ededed;
  border-radius: 0.71rem;
  padding: 2.15rem 1.53rem;
  box-shadow: 14px 0px 25px 0px rgba(236, 236, 236, 0.35);
}

.timing_box .heading {
  color: #222222;
  font-size: 1.84rem;
  font-family: "linotte-semibolduploaded_file";
}

.timing_box span {
  font-size: 1.38rem;
  color: #222222;
}

.timing_box span+span {
  color: #626262;
  font-size: 1.071rem;
}

.timing_box .btn {
  color: #cccccc;
  font-size: 1.076rem;
  background: #fff;
  border-color: #cccccc;
  min-width: 7.82rem;
  min-height: 2.14rem;
}

.profile_mobile {
  position: relative;
  padding-left: 0.5rem;
}

.profile_mobile .profile_view_btn {
  background: none;
  border: 0;
  padding: 0 !important;
  color: #626262;
}

.profile_mobile .profile_view_btn i {
  font-size: 1.7rem;
}

.profile_mobile .profile_view_btn.btn-success:hover,
.profile_mobile .profile_view_btn.btn-success.dropdown-toggle {
  background: #fff;
  color: #626262;
}

.profile_mobile .profile_dropedown {
  display: flex;
  position: absolute;
  right: 0;
  background: #fff;
  width: 240px;
  padding: 1rem;
  z-index: 1;
}

.profile_mobile .dropdown-toggle::after {
  display: none;
}

.mentor_box .slick-list {
  padding-bottom: 10px;
}

.dashboard_inner h2.page_heading {
  font-size: 2.3rem;
  font-family: "linotte-heavyuploaded_file";
}

.changepassword .btn {
  background: transparent;
  color: #ef4343;
  font-size: 16px;
}

.changepassword .btn:hover {
  background: #0078ff;
  color: #fff;
}

.studentformdashboard img {
  border: 6px solid #e5f1ff;
  opacity: 0.8;
}

.studentformdashboard form {
  width: 90%;
  margin: 20px auto 30px;
}

.studentformdashboard h4 {
  font-size: 1.50rem;
  font-family: "linotte-semibolduploaded_file";
}

.dropdownouter button {
  background: transparent !important;
  border: 0px !important;
  color: #a8a8a8 !important;
  box-shadow: none !important;
}

.dropdownouter button::after {
  color: #0078ff;
}

.studentformdashboard select.form-control.custom-select {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAJCAYAAAACTR1pAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkMzOTI5QTA1NEYyMDExRUJBN0M2RkYyOTRDRUNFNkIzIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkMzOTI5QTA2NEYyMDExRUJBN0M2RkYyOTRDRUNFNkIzIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzM5MjlBMDM0RjIwMTFFQkE3QzZGRjI5NENFQ0U2QjMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzM5MjlBMDQ0RjIwMTFFQkE3QzZGRjI5NENFQ0U2QjMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7YrKqGAAAAxklEQVR42mI8c+6eCAMDw1YgLjY2VDzCgAecPX/fBkj1ArE3E5BYD8RmIM1ACXM8msyhFoDUrgdp7Afif0DMB8Q7gAp0sWgCie2AqgGp7WcCOm8dkBEHFRAA4n1AhdpImkDsfVA5kJo4kB7G////wxSkAamZUPVPgdgByj4AxNJQdjpQ0ywQA64RqjkbSE2Bcp9AaRkonQPUNBWmFkUjVHMFkGpH82YlUFMHsgATekBAFTQhCTWha8JqI5LNHVCDKrDJAwQYAI/aTECtQ7VpAAAAAElFTkSuQmCC);
  background-size: 15px;
}

.studentformdashboard .form-group.position-relative span {
  padding: 12px 12px;
  border-radius: 6px 0px 0px 5px;
}

.studentformdashboard .form-group {
  margin-bottom: 20px;
}

.studentformdashboard .loginsubmit.nextbtn {
  width: 100px !important;
}

button,
.btn {
  box-shadow: none !important;
}

.studentformdashboard span.uploadfile {
  top: 26px;
  border-radius: 5px;
  padding: 17px 25px;
}

.selectoption button {
  margin-right: 10px;
  margin-bottom: 10px;
  background: transparent;
  border: 1px solid #cccede;
  color: #626262;
  font-family: "linotte-regularuploaded_file";
}

.selectoption button i {
  font-size: 15px;
  margin-left: 10px;
  margin-right: 5px;
  color: #ff3737;
  position: relative;
  top: 1px;
}

.selectoption button:hover,
.selectoption button:active,
.selectoption button:focus {
  background: transparent !important;
  border-color: #ff3737 !important;
  color: #ff3737 !important;
}

nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: #dc3545 !important;
}

.loader {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999999999;
}

.custom_check {
  display: inline-block;
  position: relative;
  cursor: pointer;
  line-height: 22px;
  position: relative;
  padding-left: 30px;
  margin: 5px 0px;
}

.custom_check input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 2;
}

.custom_check .check_indicator {
  height: 22px;
  width: 22px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 0px;
}

.custom_check input:checked+.check_indicator:before {
  width: 7px;
  height: 12px;
  position: absolute;
  left: 6px;
  top: 2px;
  border: solid #fff;
  border-width: 0px 2px 2px 0px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
}

.form-text-custom {
  font-size: 1.24rem;
  font-family: "linotte-regularuploaded_file";
  color: #000 !important;
}

.container-fluid {
  padding: 0 3.07rem;
}

.dashboard_header {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 10px 0px rgba(150, 145, 145, 0.1);
  z-index: 10;
  position: fixed;
  width: 100%;
}

.DashHeader_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.DashHeader_right .user {
  display: flex;
  align-items: center;
  color: #222;
  font-size: 1.23rem;
  /* margin-right: 1.92rem; */
  width: 41px;
  flex: 0 0 41px;
  height: 41px;
  margin-right: 12px;
  border: 2px solid #e3e3e3;
  border-radius: 100%;
}

.DashHeader_right .user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.mail_notification a {
  border-radius: 50%;
  background-color: rgb(231, 231, 234);
  width: 41px;
  height: 41px;
  flex: 0 0 41px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mail_notification a span {
  border-radius: 50%;
  background-color: rgb(243, 94, 69);
  position: absolute;
  top: -2px;
  right: 4px;
  width: 8px;
  height: 8px;
}

.dashboard_outer {
  padding-top: 91px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  background: #f9f9f9;
}

.dashboard_sidebar {
  width: 320px;
  /* height: 100%; */
  background: #0078ff;
  padding: 40px 0;
  position: fixed;
  bottom: 0;
  top: 91px;
  overflow-y: auto;
  z-index: 1;
}

.dashboard_inner {
  width: calc(100% - 320px);
  padding: 2.3rem 3.46rem;
  margin-left: 320px;
  min-height: 100vh;
}

.dashboard_profile img {
  width: 8.46rem;
  height: 8.46rem;
  border-radius: 100%;
  border: 6px solid #1a86ff;
  margin: 0 auto 15px;
  display: block;
}

.dashboard_profile h5 {
  color: #fff;
  font-size: 1.84rem;
  font-family: "linotte-semibolduploaded_file";
}

.dashboard_menu ul li a {
  color: #88baf2;
  font-size: 1.53rem;
  font-family: "linotte-regularuploaded_file";
  display: flex;
  align-items: center;
  padding: 15px 1.53rem;
}

.dashboard_menu ul li a span {
  margin-right: 12px;
  opacity: 0.6;
}

.dashboard_menu ul li a.active,
.dashboard_menu ul li a:hover {
  background: #0058bb;
  color: #fff;
}

.dashboard_menu ul li.active a span,
.dashboard_menu ul li a:hover span {
  opacity: 1;
}

.dash_heading {
  margin-bottom: 12px;
  font-size: 2.3rem;
  color: #222222;
  font-family: "linotte-bolduploaded_file";
}

.mentor_box h3 {
  color: #222222;
  font-size: 1.50rem;
  font-family: "linotte-bolduploaded_file";
  margin-bottom: 1.53rem;
}

.box_wrap {
  padding: 2.3rem 2.69rem;
}

.mentor_session {
  margin-top: 35px;
  padding-top: 35px;
  border-top: 1px solid #eaeaea;
}

.session_outer p {
  margin: 0;
  display: flex;
  align-items: center;
}

.session_outer p h4 {
  margin: 0;
  color: #222222;
  font-size: 22px;
  font-family: "linotte-semibolduploaded_file";
}

.session_outer p h4 span {
  display: block;
  color: #626262;
  font-size: 18px;
  font-family: "linotte-regularuploaded_file";
}

.session_outer p .icon {
  border-radius: 50%;
  background-color: rgb(234, 242, 250);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.session_outer p+p {
  margin-left: 100px;
}

.session_outer .review_btn {
  color: #0078ff;
  border: 1px solid #0078ff;
  font-size: 18px;
  font-family: "linotte-semibolduploaded_file";
  border-radius: 4px;
  display: inline-flex;
  padding: 15px 20px;
  float: right;
  align-items: center;
}

.sidebar_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0;
  transition: 0.4s ease all;
  visibility: hidden;
}

.mw-950 {
  margin: auto;
  max-width: 950px;
}

.dash_MentorList {
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
}

.dash_MentorList .col {
  width: 20%;
  flex-basis: auto;
}

.dash_MentorList .mentor_img img {
  width: 100%;
}

.dash_MentorList h3 {
  color: #0078ff;
  font-size: 1.7rem;
  margin-bottom: 5px;
}

.signup_class span.err_msg {
  position: absolute;
}

.customeValidationText {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.MuiFormControl-root .MuiInputBase-input {
  border: 0px !important;
  padding: 9px;
  font-size: 1.2rem;
  color: #a8a8a8;
}

.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: absolute;
  transform: translate(0, 24px) scale(1);
  font-size: 1.2rem;
  color: #a8a8a8;
  font-family: "linotte-regularuploaded_file";
}

.MuiInputLabel-formControl {
  font-size: 1.2rem !important;
}

.MuiInput-underline:after,
.MuiInput-underline:hover:not(.Mui-disabled):before,
.MuiInput-underline:before {
  border-bottom: 1px solid rgb(204 206 222) !important;
}

.MuiFormControl-root .MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(0.75) !important;
}

/* chatbox */

.msgs-list {
  float: left;
  width: 100%;
  background-color: #ededed;
  border-left: 1px solid rgb(230 230 230 / 55%);
  border-bottom: 1px solid rgb(230 230 230 / 55%);
  border-right: 1px solid rgb(177 177 177 / 27%);
}

.leftpartheader {
  padding: 8px 15px;
  justify-content: space-between;
  position: relative;
}

span.userimg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

span.userimg img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.mytabopen li a {
  color: #9c9c9c;
  padding: 0px 5px;
}

.searchinput {
  position: relative;
  padding: 6px 10px;
  background: #f6f6f6;
}

.searchinput .form-control {
  height: 35px;
  padding: 5px;
  border-radius: 55px;
  border: 0px;
  padding-left: 39px;
  font-size: 15px;
}

.searchinput .fa-search {
  position: absolute;
  top: 17px;
  left: 23px;
  font-size: 14px;
  color: #919191;
}

.messages-list {
  float: left;
  width: 100%;
  background: #fff;
  max-height: 676px;
  overflow: auto;
}

.messages-list ul li {
  float: left;
  width: 100%;
  padding: 8px 15px;
  border-bottom: 1px solid rgb(230 230 230 / 55%);
  cursor: pointer;
}

.usr-msg-details {
  float: left;
  position: relative;
  width: 100%;
  display: flex;
}

.usr-ms-img {
  float: left;
  width: 50px;
  position: relative;
}

.usr-mg-info {
  /* float: left; */
  padding-left: 13px;

  width: calc(100% - 50px);
}

.usr-mg-info h3 {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.usr-mg-info p {
  color: #686868;
  font-size: 14px;
  margin: 0px;
}

.usr-ms-img img {
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
}

.posted_time {
  /* position: absolute;
  top: 2px;
  right: 0; */
  color: #b2b2b2;
  font-size: 12px;
}

.messages-list ul li:hover {
  background: #f6f6f6;
}

.msg-notifc {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 25px;
  height: 25px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  background-color: #f26522;
  text-align: center;
  line-height: 28px;
  font-size: 13px;
  color: #fff;
}

.main-conversation-box {
  float: left;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
  border-right: 1px solid rgb(230 230 230 / 55%);
  border-bottom: 1px solid rgb(230 230 230 / 55%);
  padding-top: 85px;
}

.message-bar-head {
  float: left;
  width: 100%;
  background-color: #c7c7c7;
  padding: 8px 15px;
  border-bottom: 1px solid #eaeaea;
  /* position: absolute; */
  top: 0;
  left: 0;
  z-index: 8;
}

.message-bar-head .usr-msg-details {
  /* float: left;
  position: relative;
  width: auto; */

  display: flex;
  align-items: baseline;
}

.message-bar-head>a {
  float: right;
  color: #b2b2b2;
  font-size: 20px;
  padding-top: 10px;
}

.main-message-box {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 15.5px;
}

.message-dt {
  float: left;
  width: auto;
  padding-left: 85px;
}

.message-inner-dt {
  float: left;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  width: 100%;
}

.message-inner-dt>p {
  float: right;
  width: 60%;
  background-color: #f26522;
  font-size: 14px;
  line-height: 22px;
  margin: 0px;
  padding: 10px 35px 10px 35px;
  color: #fff;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}

.message-dt.st3 .message-inner-dt>p {
  background-color: #efefef;
  color: #686868;
  width: auto;
  padding: 10px 15px;
  float: left;
}

.message-dt.st3 .message-inner-dt>p img {
  float: right;
  position: relative;
  top: 3px;
  padding-left: 5px;
}

.messg-usr-img {
  position: absolute;
  bottom: 25px;
  left: 20px;
  width: 50px;
}

.main-message-box.st3 .messg-usr-img {
  bottom: 13px;
}

.main-message-box.ta-right {
  float: right;
}

.main-message-box.ta-right .message-dt {
  padding-left: 0;
  padding-right: 85px;
}

.message-inner-dt {
  float: left;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  width: 100%;
}

.message-inner-dt>p {
  float: right;
  width: 60%;
  background-color: #e44d3a;
  font-size: 14px;
  line-height: 22px;
  padding: 10px 35px 10px 35px;
  color: #fff;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}

.main-message-box.ta-right .message-dt>span {
  float: right;
  width: auto;
}

.main-message-box.ta-right .messg-usr-img {
  left: auto;
  right: 20px;
  bottom: 25px;
}

.messg-usr-img img {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
}

.message-dt>span {
  color: #b2b2b2;
  font-size: 12px;
  float: left;
  width: 100%;
  margin-top: 7px;
}

.messages-line {
  height: 640px;
  width: 100%;
  float: left;
  overflow: auto;
}

.message-send-area {
  background: #f0f0f0;
  float: left;
  width: 100%;
  height: 64px;
}

.message-send-area form {
  display: flex;
}

.message-send-area form {
  display: flex;
  align-items: center;
  padding: 10px;
}

.message-send-area form li {
  display: inline-block;
  margin: 0px 2px;
}

.message-send-area form li a {
  font-size: 20px;
  color: #919191;
  padding: 0px 5px;
}

.sendmessage {
  display: block;
  width: calc(100% - 30px);
}

.sendmessage input {
  border-radius: 55px;
  border: 0px;
  margin: 0px 10px 0px 0px;
  width: calc(100% - 10px);
  box-sizing: border-box;
  height: inherit;
}

.Container {
  display: flex;
  padding: 10px;
}

.UsersCard {
  margin: 5px 20px;
}

.MessageForm {
  margin-left: 10px;
}

.MessageBox {
  float: left;
  width: 100%;
  padding: 5px 0px;
  margin: 0px 0px;
}

.MessageBox .ChatMessage {
  width: 100%;
  float: left;
  min-height: 40px;
}

.MessageBox .ChatMessage .RightBubble {
  min-width: 264px;
  max-width: 65%;
  position: relative;
  background: #dcf8c6;
  border-top-left-radius: 0.4em;
  border-bottom-left-radius: 0.4em;
  border-bottom-right-radius: 0.4em;
  padding: 5px 10px 10px;
  display: inline-block;
  float: right;
  margin-right: 30px;
}

.MessageBox .ChatMessage .RightBubble span.MsgName {
  font-size: 12px;
  font-weight: bold;
  color: green;
}

.MessageBox .ChatMessage .RightBubble span.MsgDate {
  font-size: 10px;
}

.MessageBox .ChatMessage .RightBubble:after {
  content: "";
  position: absolute;
  right: 0;
  top: 13px;
  width: 0;
  height: 0;
  border: 27px solid transparent;
  border-left-color: #dcf8c6;
  border-right: 0;
  border-top: 0;
  margin-top: -13.5px;
  margin-right: -27px;
}

.MessageBox .ChatMessage .LeftBubble {
  min-width: 200px;
  max-width: 65%;
  position: relative;
  background: lightblue;
  border-top-right-radius: 0.4em;
  border-bottom-left-radius: 0.4em;
  border-bottom-right-radius: 0.4em;
  display: inline-block;
  float: left;
  padding: 5px 10px 10px;
  margin-left: 30px;
}

.MessageBox .ChatMessage .LeftBubble span.MsgName {
  font-size: 12px;
  font-weight: bold;
  color: blue;
}

.MessageBox .ChatMessage .LeftBubble span.MsgDate {
  font-size: 10px;
}

.MessageBox .ChatMessage .LeftBubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 13px;
  width: 0;
  height: 0;
  border: 27px solid transparent;
  border-right-color: lightblue;
  border-left: 0;
  border-top: 0;
  margin-top: -13.5px;
  margin-left: -27px;
}

.MessageBox .ChatStatus {
  min-height: 49px;
}

.MessageBox .ChatStatus .ChatDate {
  display: block;
  font-size: 10px;
  font-style: italic;
  color: #777;
  height: 15px;
  left: 10%;
  right: 10%;
}

.MessageBox .ChatStatus .ChatContentCenter {
  padding: 5px 10px;
  background-color: #e1e1f7;
  border-radius: 6px;
  font-size: 12px;
  color: #555;
  height: 34px;
  left: 10%;
  right: 10%;
}

.ChatContent {
  /* overflow-y: scroll; */
  height: 580px;
}

.no-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9ecef;
  min-height: 50rem;
}

.jumbotron_leftsec,
.jumbotron_rightsec {
  min-height: 40rem !important;
}

.no-chat div {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

.no-chat .heading {
  font-size: 2rem;
  font-family: "linotte-bolduploaded_file";
}

.no-chat p {
  font-size: 1.2rem;
  line-height: 1.5;
}

.chat_icon {
  width: 20rem;
  height: 20rem;
  background-color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
}

.message-bar-head {
  margin-top: 52px;
}

.jumbotron_leftsec,
.jumbotron_rightsec {
  height: 100%;
  background-color: #e9ecef;
  min-height: 50rem;
}

.jumbotron_leftsec .jumbotron {
  width: 100%;
  float: left;
  padding: 15px;
  margin: 0rem 0rem;
}

.jumbotron_leftsec .jumbotron ul {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
}

.jumbotron_leftsec .jumbotron ul li {
  width: 100%;
  float: left;
  cursor: pointer;
  background-color: #ffffff;
  padding: 1.07rem;
  margin: 2px 0px;
}

.jumbotron_leftsec .jumbotron ul li.chatActive {
  background-color: #fff3f3;
}

.jumbotron_rightsec {
  position: relative;
  /*padding: 15px;*/
  padding-top: 80px;
  padding-bottom: 75px;
}

.jumbotron_rightsec .StickyFooter {
  width: 100%;
  position: absolute;
  bottom: 0%;
  left: 0%;
  border: 0px;
  background-color: #c7c7c7;
}

.jumbotron_rightsec .StickyFooter .MessageForm {
  margin-left: 0px;
}

.jumbotron_rightsec .StickyFooter .MessageForm .form-control {
  height: 60px;
}

.MsgDate {
  float: right;
  margin-top: 5px;
}

.chat_message::first-letter {
  text-transform: uppercase;
}

.noMessage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noMessage img {
  width: 25rem;
  height: 25rem;
}

.businessboxouter>div {
  margin-bottom: 0px;
}

.current-mentees .link_btn.btn {
  font-size: 14px;
  margin: 5px 5px 0 0;
}

.current-mentees {
  flex-wrap: wrap;
}







@media (max-width: 1365.98px) {

  .about_how_content {
    padding-top: 100px;
  }

  .how_img {
    position: absolute !important;
    width: 42% !important;
    z-index: 1;
  }

 


}



@media (max-width: 991.98px) {

  .about_how_content {
    padding-top: 110px;
  }

  .about_how_content .btn.btn_process {
    margin-left: 0 !important;
  }

  .member_img {
    height: 300px;
  }

  .linkpart ul li {
    min-height: 164px !important;
  }

  .linkpart ul li a {
    padding: 20px 0 !important;
  }

  .who-thumb-outer.row {
    padding-left: 0px !important;padding:64px 0;
  }


  .nav-link.nav-item.dropdown {
    padding: 0 !important;
  }

  .navigation .navbar-nav .nav-link:not(.navigation .navbar-nav .nav-link.nav-item) {
    width: 100%;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid #e7e7e7;
  }

  .header_login {
    padding-top: 20px;
    padding-left: 0;
  }

  .navbar-nav .dropdown-menu {
    position: relative;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .navbar-light .navbar-nav {
    flex-wrap: wrap;
  }

  .navigation .navbar-nav .nav-link {
    flex-wrap: wrap;
  }




}


@media (max-width: 575.98px) {

  .mentro_details_leftbar {
    width: 100% !important;
  }

  .mentro_details_sidebar {
    width: 100% !important;
  }

  .answer_count,
  .answer_list_left .btn {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .answer_count {
    text-align: center;
  }

  .answer_list_left .btn {
    margin-top: 9px;
  }

  .profile_btn {
    width: 100%;
    display: block;
    text-align: center;
  }

  .mentro_details_leftbar img:not(.link_btn img) {
    width: 100%;
  }

  .mentro_details_sidebar .col-xl-4 .d-flex {
    display: block !important;
    width: 100%;
    text-align: center;
    background: #f9f9f9;
    padding: 10px;
    margin: 10px 0;
  }

  .profile_btn .link_btn.btn {
    padding: 6px 15px;
  }



  .community_page_inner .container .group_c a:first-child {
    font-size: 13px;
    padding: 10px;
  }

  .community_content h2 {
    font-size: 18px;
    line-height: 2;
  }


  .mentor_img {
    height: auto !important;
  }

  .member_box.text-center .member_img {
    height: 480px !important;
  }

  .btn_process {
    min-width: initial;
  }

  .dashboard_outer .dashboard_inner .mentor_img {
    height: auto !important;
  }

  .navbar-light .navbar-toggler {
    margin-right: 10px;
  }

  .DashHeader_right .navbar-expand-lg {
    padding-bottom: 0;
    padding-top: 0;
  }

}




@media (max-width: 479.98px) {


  .studentformdashboard .loginsubmit.nextbtn {
    min-width: 80px !important;
    line-height: 1;
    min-height: 36px;
    font-size: 14px;
    width: auto !important;
    margin-right: 0 !important;
  }

  .leader_img {
    height: 260px !important;
  }

  section.outer_main .linkpart ul li i {
    font-size: 36px;
  }

  section.outer_main .linkpart ul li {
    min-height: 150px !important;
  }



}





@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {

  .banner-Cap h1 { font-size: 32px!important;}

  .about_how_work:before {
    top: 70px !important;
  }

  .about_how_content {
    padding-top: 50px;
  }

  /* .faqouter .card-body {
    padding: 0px 20px 8px;
  } */
  /* .answer_top_info{
   flex-direction: column-reverse;
 align-items: normal!important;

 } */
  .answer_list_left {
    flex: 0 0 85%;
    max-width: 85%;
  }

  /* .answer_bottom{
   flex-direction: column;
   align-items: normal!important;
 } */
  .timeing {
    margin-bottom: 9px;
    flex: 0 0 15%;
    max-width: 15%;
    font-size: 14px !important;
  }

  .forum_contant_body h4 {
    font-size: 20px !important;
  }

  .posted_by {
    font-size: 14px;
  }


  

  .studentformdashboard .col-md-6 .col-sm-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .studentformdashboard span.uploadfile {
    top: 34px !important;
    padding: 10px 15px !important;
  }
}


@media screen and (min-device-width: 768px) and (max-device-width: 991px) {

  .newsletter-html td {
    background-size: 100% !important;
    box-sizing: border-box;
    width: 100% !important;
  }


  body .newsletter-html table {
    max-width: 100% !important;
    /* table-layout: fixed; */
    table-layout: auto !important;
  }

  .newsletter-html * {
    box-sizing: border-box !important;
  }

  .dashboard_inner .mentor_img {
    height: 260px !important;
  }

  .answer_top_info {
    flex-direction: column;
    position: relative;
  }

  .answer_list_left {
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;

  }

  /* .answer_bottom{
     flex-direction: column;
     align-items: normal!important;
   } */
  .timeing {
    margin-bottom: 9px;
    font-size: 14px !important;
    text-align: left;
    display: inline;
    width: 100%;
    margin-top: 11px;

  }

  .answer_list_left .btn.report_to_admin {

    margin-top: 9px;

  }

  .posted_by {
    padding: 13px 0px 0px 0px;
  }

  .answer_report_to_admin {
    width: 100%;
  }

  .answer_list_left .btn.likes_count {
    margin-top: 9px;
  }

  .answer_bottom {
    flex-wrap: wrap;
  }

  .faqouter .card-header button.btn {
    font-size: 1rem;
  }

  .answer_count {
    font-size: 14px !important;
    padding: 7px 10px !important;
  }

  .answer_count {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .forum_contant_body h4 {
    font-size: 17px !important;
  }

  .posted_by {
    font-size: 14px;
  }

  .navbar-light .navbar-toggler {
    margin-right: 10px;
  }

  .studentformdashboard .col-md-6 .col-sm-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .studentformdashboard span.uploadfile {
    top: 27px !important;
    padding: 17px 25px 16px !important;
    right: 18px !important;
  }

  .about_how_work:before {
    top: 70px !important;
  }

  .about_how_content {
    padding-top: 50px;
  }



}



@media (max-width: 767.98px) {

  .studentformdashboard .form-group.position-relative span {
    padding: 8px !important;
  }

  /* chat */

  .answer_top_info {
    flex-direction: column;
    position: relative;
  }

  .answer_list_left {
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;

  }

  .forum_contant_body h4 {
    font-size: 16px !important;
  }

  .answer_bottom {
    flex-wrap: wrap;
  }

  /* .answer_bottom{
   flex-direction: column;
   align-items: normal!important;
 } */
  .timeing {
    margin-bottom: 9px;
    font-size: 13px !important;
    text-align: left;
    display: inline;
    width: 100%;
    margin-top: 11px;

  }

  .answer_list_left .btn.report_to_admin {

    margin-top: 9px;

  }

  .posted_by {
    padding: 13px 0px 0px 0px;
    font-size: 13px;
  }

  .answer_report_to_admin {
    width: 100%;
  }

  .answer_list_left .btn.likes_count {
    margin-top: 9px;
  }

  .answer_bottom {
    flex-wrap: wrap;
  }

  .faqouter .card-header button.btn {
    font-size: 1rem;
  }

  .answer_count {
    font-size: 14px !important;
    padding: 7px 10px !important;
  }

  .answer_count {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* chat */



  .whoamform .form-group.position-relative span {
    padding: 11px;
  }

  .studentformdashboard .form-group.position-relative input,
  .whoamform .form-group.position-relative input {
    padding-left: 50px !important;
  }


  .how_img {
    position: relative !important;
    width: 100% !important;
  }

  .about_how_work:before {
    transform: skew(0) !important;
  }

  .dashboard_inner .mentor_img {
    height: 250px !important;
  }

  .studentformdashboard .form-group.position-relative span {
    padding: 8px 12px;
  }

  .sec-heading .title {
    padding-right: 0px;
  }

  .about_how_content {
    padding-top: 50px;
  }

  .businessboxouter .form-check-input {
    margin-left: 0 !important;
  }

  .businessboxouter {
    width: 100%;
  }

  .changepassword .btn {
    font-size: 14px;
  }

  span.inputfileouter input[type=file] {
    padding: 10px;
  }

  span.inputfileouter {
    padding: 10px 15px;
  }

  .form-control {
    padding: 10px !important;
    margin-bottom: 10px;
  }

  .studentformdashboard span.uploadfile {
    padding: 11px;
  }

  .navbar-toggler {
    margin-right: 10px;
  }

  .studentformdashboard .form-group.position-relative span {
    padding: 8px;
  }

  .studentformdashboard form {
    margin: 0 !important;
    width: 100%;
  }


  .studentformdashboard span.uploadfile {
    padding: 14px 15px;
    right: 16px;
  }

  .studentformdashboard .form-group.position-relative span {
    padding: 8px 12px;
  }



  .member_img {
    height: 250px !important;
  }

  .members_sec .slick-list .slick-track .slick-slide:nth-child(odd) {
    margin-top: 0;

  }


  .linkpart ul {
    flex-wrap: wrap;
  }

  .linkpart ul li {
    min-height: 200px !important;
    flex: 0 0 50% !important;
    max-width: 50%;
  }

  .linkpart ul li a {
    padding: 32px 0 !important;
  }

  .leader_sec .slick-dots {
    bottom: -50px;
  }

  .who-thumb-outer.row {
    padding-left: 0 !important;
  }

  .who_box {
    height: auto !important;
  }

  .posted_time {
    position: static;
    float: right;
    margin-top: 10px;
  }

  .jumbotron_leftsec,
  .jumbotron_rightsec {
    min-height: inherit;
  }
}

/* chatbox */
.jumbotron_leftsec {
  width: 30%;
  border-right: 5px solid #fff;
}

.message-bar-head {
  width: 67%;
}

.jumbotron_rightsec {
  padding: 35px 21px;
}

.chetboxouter .jumbotron_rightsec .ChatContent {
  height: calc(100vh - 312px);
}

.react-scroll-to-bottom--css-vvlnw-1tj0vk3 {
  top: 242px;
}

.followButtonClassName {
  bottom: 66px !important;
}

/* chatbox */

.change_reset_password {
  width: 83%;
}

/** notice modal custom css start**/
.Dialog-style_dialog__30eYR {
  background-color: rgba(0, 0, 0, 0.5);
}

.Dialog-style_dialog-message__3w_gn {
  color: #626262;
  font-size: 16px;
  line-height: 24px;
  background-color: #fff;
}

.Dialog-style_dialog-button-ok__dojbz {
  font-family: "linotte-semibolduploaded_file";
  font-size: 18px;
  padding: 12px 15px;
}

/** notice modal custom css finsh**/

.disabledCursor {
  cursor: no-drop !important;
}

.navigation.cms-header {
  display: none;
}


.mentor_info h5 {

  margin-bottom: 5px;
}

.main_area_Details h5 {

  margin-bottom: 2px;
}

.navbar-light .navbar-nav .nav-link {
  color: #222222;
  font-family: "linotte-bolduploaded_file";
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:hover {
  color: #ef4343;
}

.field_icon {
  position: relative;
}

.field_icon .form-control {
  padding-right: 35px;
}

.field_icon .icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #cccede;
  font-size: 1.3rem;
}




.communities_sec {
  position: relative;
  padding: 8rem 0rem 8rem 0;
  /* background-image: url("../images/communities_curve.png");
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: 100% 100%; */
}

.communities_info {
  border: 1px solid #f1f1f1;
  overflow: hidden;
  border-radius: 0.45rem;
  box-shadow: 0px 0px 4px 0px rgba(117, 117, 117, 0.1);
  background-color: #fff;
}

.communities_info .heading {
  color: #222222;
  font-size: 1.57rem;
  font-family: 'linotte-bolduploaded_file';
}

.communities_info p {
  color: #808080;
  font-size: 1.14rem;
}

.communities_info .members_tag {
  font-size: 1.14rem;
  color: #808080;
  display: flex;
}

.communities_info .members_tag i {
  color: #2e70fb;
  font-size: 1.5rem;
}

.communities_info .link_group {
  background-color: #fff;
  color: #ef4343;
  min-width: 8.71rem;
}

.communities_info .link_group:hover {
  color: #fff;
  border-color: #ef4343;
  background-color: #ef4343;
}

.communities_sec .slick-slide {
  padding: 0 6px;
}

 

.newsletter_sec .banner_form .form-control {
  height: 3.50rem;
}

.newsletter_content .heading {
  color: #222222;
  font-family: 'linotte-bolduploaded_file';
  font-size: 2.2rem;
}

.newsletter_content p {
  color: #222222;
  font-size:2rem;
}


/* the parent */

.resource_user {
  width: 3.64rem;
  height: 3.64rem;
  overflow: hidden;
  border-radius: 100%;
}

.resource_user img {
  object-fit: cover;
  height: 100%;
}

.resource_name .name {
  color: #222;
  font-size: 1.42rem;
}

.resource_name .date {
  font-size: 1.14rem;
  color: #808080;
}

.like_link {
  color: #808080;
  font-size: 1.14rem;
}

.like_link i {
  color: #ef4343;
  font-size: 1.5rem;
}

.like_link:hover i {
  color: #0078ff;
}

.social_links li {
  padding: 0 0.6rem;
}

.social_links a {
  color: #757575;
  font-size: 1.71rem;
}

.social_links a:hover {
  color: #0078ff;
}

.resources_detail_img {
  padding-bottom: 3rem;
}

.page_content_inner p {
  font-size: 1.28rem;
  line-height: 1.5;
}





.resourcestabinnercontent {
  height: 350px;
  width: 100%;
  background-size: cover;
  position: relative;
  background-position: center;
  color: #fff;
  padding: 15px;
  display: flex;
  align-items: flex-end;
}

.resourcestab {
  margin-bottom: 15px;
}

.resourcestab a {
  display: inline-block;
  width: 100%;
}

.resourecimg {
  width: 50px;
  height: 50px;
}

.resourecimg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.resourcecontent {
  width: calc(100% - 50px);
  padding-left: 15px;
}

.resourcestabinnercontent h3 {
  font-size: 2rem;
  margin-bottom: 10px;
  font-family: 'linotte-bolduploaded_file';
}

/* 24/2/2021 */

.community_section {
  padding: 7.3rem 0 4rem;
}

.community_section .content_inner .back_img {
  height: 800px;
  width: 100%;
  object-fit: cover
}

.community_section .content_inner {
  position: relative;
}

.community_section .content_inner .shape_img {
  position: absolute;
  bottom: 0;
  right: 0px;
  left: 0;
}

/* community_page_inner */

.community_content h2 {
  color: rgb(34, 34, 34);
  font-weight: 600;
  line-height: 2.2;
  font-family: 'linotte-bolduploaded_file';
}

.community_page_inner .container .row_c {
  border-bottom: 1px solid rgb(233, 233, 233);
  padding-bottom: 20px;
}

.community_content ul li {
  font-size: 1.2rem;
  font-family: 'linotte-regularuploaded_file';
  color: rgb(128, 128, 128);
  margin-right: 26px;
  display: inline-block;
}

.community_content ul li span {
  margin-right: 8px;

}

.community_page_inner {
  margin-top: -29rem;
  position: relative;
}

.community_page_inner .container {
  background-color: white;
  padding: 2.57rem 3rem;
  border-radius: 11px;
}

.community_page_inner .container .group_c a:nth-child(1) {
  background-color: #ef4343;
  color: #fff;
  padding: 11px 20px;
  border-radius: 6px;
  font-family: 'linotte-semibolduploaded_file';
  width: 100%;
  text-align: center;
}

.community_page_inner .container .group_c a:nth-child(2) {
  background-color: #eaf2fa;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  min-width: 50px;
}

.community_page_inner .container .community_main_inner h2 {
  color: rgb(34, 34, 34);
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
  font-family: 'linotte-bolduploaded_file';
}

.community_page_inner .container .community_main_inner p {
  font-size: 16px;
  color: rgb(98, 98, 98);
  line-height: 1.5;
  margin: 0px;
}

.community_page_inner .container .community_main_inner>ul li {
  font-size: 16px;
  color: rgb(98, 98, 98);
  line-height: 1.5;
  margin: 0px;
  display: inline-flex;
}

.community_page_inner .container .community_main_inner ul li .fa {
  font-size: 7px;
  margin-right: 28px;
}

.community_page_inner .container .sidebar_community .heading h2 {
  font-size: 20px;
  color: rgb(34, 34, 34);
  font-weight: bold;
  line-height: 1.2;
  font-family: 'linotte-semibolduploaded_file';
}

.community_page_inner .container .sidebar_community .heading a {
  font-size: 16px;
  color: rgb(239, 67, 67);
  font-weight: bold;
  line-height: 1.2;
  font-family: 'linotte-semibolduploaded_file';
}

.community_page_inner .container .whole_content:after {
  content: "";
  position: absolute;
  right: 0;
  top: -21px;
  background-color: rgb(233, 233, 233);
  width: 1px;
  height: 100%;
}

.community_page_inner .container .sidebar_community .member_list ul li {
  padding: 10px 4px;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 5px;

  background-color: rgb(255, 255, 255);
  transition: 0.4s ease all;
  overflow: hidden;
  position: relative;
}

.community_page_inner .container .sidebar_community .member_list ul li .member_image img {
  height: 80px;
  width: 80px;
  border-radius: 5px;
  object-fit: cover;
  object-position: top;
}

.community_page_inner .container .sidebar_community .member_list ul li .member_image {
  border: 1px solid rgb(233, 233, 233);
  margin-left: 8px;
  border-radius: 9px;
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.community_page_inner .container .sidebar_community .member_list ul li .member_detail h3 {
  font-size: 18px;
  color: rgb(0, 120, 255);
  line-height: 1.2;
  font-family: 'linotte-semibolduploaded_file';
}

.community_page_inner .container .sidebar_community .member_list ul li .member_detail h4 {
  font-size: 16px;
  color: rgb(34, 34, 34);
  font-family: 'linotte-semibolduploaded_file';
  line-height: 1.5;
}

.community_page_inner .container .sidebar_community .member_list ul li .member_detail p {

  font-size: 16px;
  color: rgb(98, 98, 98);
  line-height: 1.2;

}

.community_page_inner .container .sidebar_community .member_list ul li .member_btn {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 15px;
  transition: 0.4s ease all;
  opacity: 0;
  visibility: hidden;
  text-align: center;
}

.community_page_inner .container .sidebar_community .member_list ul li .member_btn a {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.502);
  font-size: 1.23rem;
  color: #fff;
  display: block;
  text-align: center;
  padding: 12px;
  margin: 8px 13px;
  display: inline-block;
}

.community_page_inner .container .sidebar_community .member_list ul li:hover .member_btn {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.communities_inner_page {
  background-image: none;
  padding: 0rem 0rem 5rem;
}

.overflow_custom_style {
  overflow-y: auto;
  height: 662px;
}

.newcustomelinked:hover {
  text-decoration: underline;
}

/*home page testimonials content*/
.testimonials-slider-div .testimonisalPic {
  border: 3px solid #fff;
  width: 80px;
  height: 80px;
  margin: auto;
  border-radius: 100px;
  overflow: hidden;
}
.testimonials-slider-div .testimonisalPic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
 

.testimonials-slider-div h5 {
  color: #222222;
  font-size: 1.40rem;
  line-height: 1.5;
  font-weight:600;
  font-family: "linotte-regularuploaded_file";
  margin-top:6px;
}

.testimonials-slider-div span {
  font-size: 16px;
  color: #666666;
  font-weight:normal;
}


.testimonials-slider-div p {
  font-size: 18px;
  color: #808080;
  padding-bottom: 15px;
  text-align: left;
  line-height: 23px;
  padding: 32px 24px 64px 24px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
  border-top: 3px solid #C0D3F9;
  min-height: 332px;
}

/**=====img-thumbnail====**/

.leader_img {
  width: 100%;
  height: 210px;
  object-fit: cover;
}

.leader_img img {
  object-fit: cover !important;
  height: 100%;
  width: 100%;
}

.mentor_img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.mentor_img img {
  object-fit: cover !important;
  height: 100%;
  width: 100%;
}

.mentor_list .mentor_img img {
  object-fit: cover !important;
}

.who_box {
  border-radius: 10px;
  /** overflow: hidden;**/
  min-width: 100%;
  height: 240px;
}

.who_box img {
  object-fit: cover;
  height: 100%;    object-position: top;
}

.who-thumb-outer.row {
  padding-left: 70px;
}

.who-thumb-outer.row>.col-md-6 {
  padding-left: 10px;
  padding-right: 10px;
}



/* 03/06/2021 */
.communities_info > div > img {
  width: 100%;
  height: 230px;
}

.communities_info p {
  min-height: 50px;
}
 

.studentformdashboard span.uploadfile {
  font-size: 14px;
  padding: 10px 15px;
  line-height: 1.5;
  width: auto;
  right: 20px;
  top: 34px;
  background: #0078ff;
  color: #fff;
}

.loginsubmit {
  min-height: 40px;
  min-width: 120px;
  font-size: 18px;
}

.studentformdashboard label {
  font-weight: bold;
}

.member_list .link_btn img {
  display: none;
}

.member_list .link_btn {
  font-size: 14px;
  margin-top: 6px;
}

.community_page_inner .container .sidebar_community .member_list ul li .member_image img {
  object-fit: fill;
}

.community_page_inner .container .sidebar_community .member_list ul li .member_detail h4 {
  line-height: 1;
}

.community_page_inner .container .sidebar_community .member_list ul li .member_detail h3 {
  font-size: 16px;
  padding-bottom: 5px;
}

.dashboard_outer {
  padding-top: 128px;
}

.studentformdashboard form {
  margin: 20px;
}

.dropdown-menu .dropdown-item {
  padding: 8px 15px;
  border-bottom: 1px solid #ddd;
}

.dropdown-menu .dropdown-item:last-child {
  border: none;
}

.dropdown-menu {
  padding: 0;
  min-width: 150px;
}


.banner_content p {
  margin: 0;
  line-height: 1.3;
}

.banner_content h1 {
  padding: 10px 0;
  line-height: 1.2;
}

/* 09-06-2021 */


.mentor_img img {
  object-fit: initial !important;
  width: 100%;
  height: 100%;
}




/**10-6-2021**/
.linkpart ul {
  display: flex;
  justify-content: space-between;
}

.linkpart {
  padding: 0px 0 36px 0;
}

.linkpart ul li {
  flex: 0 0 16.666%;
  max-width: 16.666%;
  text-align: center;
  padding: 15px;
  border: 1px solid #ddd;
  min-height: 178px;
  transition: all ease-in-out .3s;
  -webkit-transition: all ease-in-out .3s;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkpart ul li i {
  font-size: 50px;
  margin: 10px 0;
  color: #2a6dfb;
}

.linkpart ul li a {
  color: #000;
  width: 100%;
  box-sizing: border-box;
  transition: all ease-in-out .3s;
  -webkit-transition: all ease-in-out .3s;
}

.linkpart ul li a h6 {
  font-size: 18px;
  padding: 10px 0;
}

.linkpart ul li:hover {
  margin-top: -15px;
}

.linkpart-active {
  margin-top: -15px;
}


.community_main_inner ul li {
  list-style: disc;
  font-size: 16px;
  color: #626262;
  line-height: 1.5;
  margin: 0;
}

/**=======================hover-designing-======================**/

.who_info {
  transform: scale(1);
  align-items: flex-end;
  background: transparent;
}

.hover-part {
  text-align: left;
  color: #fff;
  width: 100%;
}

.hover-part {
  transform: translateY(68%);
  transition: all ease-in-out .3s;
  -webkit-transition: all ease-in-out .3s;
}

.who_box .positionsh {
  margin-bottom: 5px;
}

.who_box:hover .hover-part {
  transform: translate(0);
}

.hover-part span.profile {
  text-align: left;
  font-size: 24px;
}

.hvrcontant .readmore {
  font-size: 14px;
  margin-top: 15px;
  color: #fff;
}

.members_sec .member_img{height:250px;}

.hvrcontant p {
  color: #fff;
}

.who_box:hover .hvrcontant,
.member_img:hover .hvrcontant {
  opacity: 1;
}

.hvrcontant .readmore i {
  padding: 15px 10px;
  font-size: 16px;
}

.advisor-contnt {
  position: absolute;
  bottom: 10px;
  left: 0;
  text-align: left;
  padding: 15px;
  width: calc(100% - 30px);
  margin: 0 15px;
  z-index: 9;
  transition: all ease-in-out .3s;
  -webkit-transition: all ease-in-out .3s;
  transform: translateY(54%);
}

.hvrcontant {
  opacity: 0;
}

.advisor-contnt .name {
  padding-bottom: 10px;
}

.positionsh {
  display: inline-block;
  width: auto;
  background: #dc3545;
  padding: 5px 10px;
  color: #fff;
  margin: 0;
}

.member_img:hover .advisor-contnt {
  transform: translateY(0);
}

.member_img:after {
  background: #00000040;
  position: absolute;
  top: 0;
  bottom: 0;
  content: "";
  height: calc(100% - 10px);
  left: 0;
  right: 0;
  margin: 5px 6px;
  border-radius: 5px;
  transition: all ease-in-out .3s;
  -webkit-transition: all ease-in-out .3s;
  opacity: 0;
}

.member_img:hover:after {
  opacity: 1;
}


.member_img {
  position: relative;
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.member_img img {
  object-fit: cover !important;
  height: 100%;
  width: 100%;
}

.advisor-contnt .name {
  color: #fff;
  font-size: 24px;
}



.who_box:after {
  background: transparent;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  transition: all ease-in-out .3s;
  -webkit-transition: all ease-in-out .3s;
}

.who_box:hover:after {
  background: #00000026;
}

.who_box .who_info.text-left {
  z-index: 9;
}

.hvrcontant .readmore {
  display: block;
}


/**===element-css=====**/

.resourcehubouter blockquote p {
  font-size: 32px;
  line-height: 1.2;
  font-style: italic;
  color: #636363;
}

.resourcehubouter blockquote {
  padding: 24px 40px;
  border-left: 2px solid #ff3644;
  margin-top: 24px;
}

.resourcehubouter ul li {
  list-style: disc;
  padding: 10px;
}

.resourcehubouter ul {
  padding-left: 20px;
}

.resources_detail_img {
  width: 100%;
}

.resourcehubouter .social_links li {
  list-style: none;
}

.resourcestab:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0000004a;
}

.resourcestab {
  position: relative;
}


.resourcestabinnercontent>div {
  position: relative;
  z-index: 2;
}

.studentformdashboard span.uploadfile {
  height: 40px;
  padding: 10px 21px;
  margin-right: 3px;
}

.about_how_work {
  max-width: 100%;
  padding: 15rem 0;
}


.about_how_work:before {
  top: 116px;
  height: 680px;
  width: 84%;
}
.about_how_content {
  position: relative;
  top: 80px;
}

.about_how_work {
  padding: 0 0 64px 0;
}

section.about_content_mian.aboutclass {
  background: #fff;
}



section.about_content_mian.aboutclass .about_content:not(.about_content_mian .about_content) {
  padding: 10rem 0 0rem 0;
}

.about_how_content .btn.btn_process {
  margin-left: 100px;
}

.aboutclass .about_content_mian {
  background: transparent;
}

section.about_content_mian.aboutclass {
  background: linear-gradient(190deg, #f9f9f9 47%, #fff 46%);
}

.aboutclass .about_content_mian .about_content {
  padding-top: 0;
}

.about_who_sec {
  padding-top: 6rem;
  margin-top:40px;
}

.slick-next,
.slick-prev {
  width: auto !important;
  height: auto !important;
}

.dashboard_inner .mentor_img {
  height: 350px;
}

.studentformdashboard .loginsubmit.nextbtn {
  min-width: 80px !important;
  line-height: 1;
  min-height: 36px;
  font-size: 14px;
  width: auto !important;
  margin-right: 0 !important;
}





.mentro_text {
  margin: 10px 0 0 0;
  padding: 10px 0;
  text-align: left;
}

.mentro_text strong {
  display: block;
  font-size: 16px;
  color: #333;
  margin-bottom: 6px;
}

.mentro_text {
  font-size: 1.28rem;
}

.mentro_text ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.mentro_text ul li {
  background: #eee;
  margin: 3px 4px;
  border-radius: 50px;
  padding: 5px 14px;
}


/**========chatbox===========**/
.chetboxouter {
  min-height: 100%;
}

.chetboxouter .jumbotron_rightsec {
  position: relative;
  padding-bottom: 80px;
  height: 100%;
  overflow: scroll;
}


.chetboxouter .jumbotron_rightsec .StickyFooter {
  left: 0;
  margin: 10px;
  width: calc(100% - 12px);
}

.jumbotron_leftsec {
  height: calc(100vh - 188px);
}

.chetboxouter .jumbotron_rightsec .MessageForm .input-group {
  align-items: flex-end;
}

.chetboxouter .jumbotron_rightsec .StickyFooter {
  background: transparent;
}

.chetboxouter .jumbotron_rightsec .StickyFooter .btn {
  border-radius: 50px;
  margin-left: 10px;
}

.chetboxouter .jumbotron_rightsec .MessageForm .input-group textarea {
  width: calc(100% - 50px);
  padding: 10px;
  max-height: 65px;
  min-height: 40px;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  overflow: auto !important;
}

.backbtn {
  display: none;
}

/**=============forup-page-design==========**/
.answer_list_left {
  display: flex;
  align-items: center;
}

.answer_count {
  padding: 7px 15px;
  border: 1px solid #eaeaea !important;
  border-radius: 5px;
  margin-right: 10px;
  color: #0079fe;
  height: 36px;
  font-size: 16px;
  font-weight: 600;
}

.answer_list_left .btn {
  padding: 5px 15px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  margin-right: 10px;
  color: #808080;
  height: 36px;
  background: #fff;
  font-size: 16px;
  font-weight: 600;
}

.answer_list_left .btn.report_to_admin {
  color: #7f7f7f !important;
}

.answer_list_left .btn.reply_btn {
  color: #ef4343;
}

.unanswer_list_left .likes_count {
  border: 1px solid #ef4343 !important;
  color: #fff !important;
  background: #ef4343 !important;
}

.answer_top_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
}

.answer_list {
  padding: 20px 0;
  border-bottom: 1px solid #eaeaea;
}

.answer_list h4 {
  font-size: 24px;
  color: #333;
  font-weight: normal;
  padding: 0 0 10px 0;
}

.answer_list p {
  font-size: 15px;
}

.answer_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 10px 0 0 0;
}

.posted_by span {
  color: #808080;
  padding-right: 7px;
  font-weight: normal;
}

.posted_by {
  color: #0079fe;
  font-weight: 600;
}

.answer_list_left .btn.likes_count {
  color: #ef4343;
}

.timeing {
  font-size: 15px;
}

.answer_count i,
.answer_list_left .btn.likes_count i,
.answer_list_left .btn.reply_btn i {
  margin-right: 5px;
}

.accordion .btn-primary:not(:disabled):not(.disabled):active {
  background: #fff;
  color: #333;
}


.ans_reply_btn {
  padding: 7px !important;
  border: 1px solid #ef4343 !important;
  color: #fff !important;
  background: #ef4343 !important;
}

.faqouter .card-header button.btn:after {
  content: "Q:";
  position: absolute;
  left: 46px;
  top: 10px;
}

.faqouter .card-header button.btn {
  position: relative;
  padding-left: 70px;
}

.answer_list {
  font-size: 15px;
  line-height: 1.4;
}

.answer_list {
  font-size: 15px;
  line-height: 1.4;
}

.answer_list_left .btn.btn-primary {
  width: auto;
  font-size: 14px;
}

.answer_list {
  position: relative;
}


.posted_by span {
  display: block;
  font-size: 13px;
}

.forum_contant_body h4 {
  font-size: 22px;
  color: #333;
  font-weight: normal;
  padding: 15px 0 0px 0;
}

.answer_list:last-child {
  border-bottom: none;
}

section.leader_sec.testimonial-slider,.communities_sec {
  background-image: url(../images/communities_curve.png);
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: 100% 100%;
}


/**=========event-css=========**/


.event-page .event-wrapper {
  background: #ececec;
}

.event-page .event-tabs {
  background: #ececec;
  display: inline-block;
  width: auto;
  text-decoration: none;
  font-size: 15px;
  color: #222222;
  padding: 10px 15px;
  margin-right: 2px;
  border-radius: 3px;
  margin-right: 10px;
}

.event-tabs-wr {
  display: inline-block;
  background: #f9f9f9;
  margin-bottom: 15px;
  padding: 0 2px 11px 0;
}

.events_listing-outer {
  padding: 15px 20px;
}

.event-page .event-tabs.active {
  background: #0078ff;
  color: #fff;
}

.event_listing {
  background: #fbfbfb;
  padding: 3px;
}

.event_listing h3 {
  line-height: 0.8 !important;
}

.event_listing h3 a {
  line-height: initial;
}

.event_listing .list_info {
  padding: 10px 16px;
  min-height: 172px;
}

.event_listing .list_info h3 {
  margin-bottom: 5px;
  font-weight: 500;
}

.event_listing .list_info h3 a {
  font-size: 18px;
  letter-spacing: 1px;
}

.events_listing-outer {
  margin: 0 -20px;
}

.events_listing-outer .col-md-4 {
  padding: 0 20px;
}


.event_listing {
  margin-bottom: 36px;
  height: calc(100% - 36px);
  padding-bottom: 54px;
  position: relative;
}

.event_listing .list_info .readmore {
  margin: 15px 0 0 0;
  display: block;
  position: absolute;
  bottom: 10px;
  padding: 10px;
}

.list_info h3 {
  font-weight: normal;
  margin: 0;
}

.list_info h3 a {
  font-size: 25px;
  text-decoration: none;
  color: #303133;
  letter-spacing: 1.2px;
}

.date,
.locationfix {
  font-size: 14px;
  color: #979899;
  padding: 8px 0 0 0;
  display: block;
}

.list_info p {
  color: #979899;
  font-size: 16px;
  padding: 10px 0;
  margin: 0;
  line-height: 21px;
}


.latest_exh_list_outer {
  position: relative;
  padding: 50px 0 20px 0;
}

.latest_exh .btn.outline_btn {
  margin-top: 0 !important;
}


.readmore {
  font-size: 14px;
  color: #2B3F73;
  text-decoration: none;
  display: block;
  margin: 5px 0 0 0;
  transition: all .3s;
  -webkit-transition: all .3s;
}

.readmore:hover img {
  margin-left: 20px;
}

.readmore img {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  margin: 0 0 0 10px;
}

.date img,
.locationfix img {
  margin-right: 6px;
}



.addeventatc {
  z-index: 0 !important;
}

.rsvp_btn {
  float: right;
  margin-top: 1.2rem;
}

.userprofile-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.crop-container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.reactEasyCrop_Container {
  height: 500px;
}

.cropper-modal .modal-body {
  height: 525px;
}





/**=========newsletter===========**/

.newslleter-cols img {
  width: 100%;
}


.post-date {
  font-size: 15px;
  color: #9f9f9f;
  display: block;
  font-weight: 500;
}

.newslleter-cols {
  padding: 0;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #dddd;
}

.newslleter-cols figure {
  margin: 0;
}

.newslleter-cols figcaption {
  padding: 15px;
}

.news-listing {
  margin-bottom: 24px;
}

.img-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.img-wrapper i {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 54px;
  color: #fff;
  transform: translateY(-50%) translateX(-50%);
}

.newslleter-cols figcaption h4 {
  font-weight: bold;
  color: #000;
  margin-bottom: 8px;
}

.cursor-pointer {
  cursor: pointer;
}



/* 17-8-2022 */
.achievements_list {
  display: flex;
  column-count: 5; 
  flex-wrap: wrap;
  width:100%;
}

.achievements_list li {
  text-align: left;
  padding: 0 24px;
  flex: 0 0 auto;
  max-width: initial;
  border-right: 1px solid #EFEFEF;
}

.achievements_list li:last-child{border-right:none;}
.achievements_list li:first-child{padding-left:0;}

.achievements_list li i {
  font-size: 30px;
  margin-bottom: 6px;
}

.achievements_list li span {
  font-size: 28px;
  font-weight: 700;
  padding: 10px 0;
  color: #0078FF; 
}


.achivment {
  padding: 36px 0;
}

.achievements_list li strong {
  font-size: 16px;
  font-weight: normal;
  display:block;
  line-height:1.6;
  color:#353535 ;
}

.testimonial-slider .slick-list {
  padding-bottom: 15px;
}

/* partnerships-page-css */



/* 18/8/22 */
.inner-partner {
  color: #222222;
  font-size: 3.46rem;
  font-family: "linotte-bolduploaded_file";
  max-width: 800px;
  margin-bottom: 3.23rem;
}

.partnership-page-inner p {
  font-size: 1.4rem;
  color: #808080;
  line-height: 30px;
}

.why-reson ul li {
  color: #808080;
  font-size: 1.8rem;
  margin-bottom: 3.07rem;
  position: relative;
}

.why-reson ul li:last-child {
  margin-bottom: 0px;
}

.why-reson ul li::before {
  position: absolute;
  left: -47px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f061";
  color: #000;
  font-size: 20px;
  top: 5px;
}

.inner-partner span {
  color: #ef4343;
  word-spacing: -6px;
}

.inner-partner span.up {
  color: #0078ff;
}

.chat-sec {
  padding-left: 15rem;
  margin-top: 7rem;
  position: relative;
}

.chat-sec::before {
  background-image: url(../images/shap1.png);
  background-repeat: no-repeat;
  width: 253px;
  height: 169px;
  content: "";
  position: absolute;
  left: -82px;
  top: -95px;
  background-size: 100%;
}

.chat-sec h3 {
  font-size: 26px;
  font-family: "linotte-semibolduploaded_file";
}

.why-reson ul {
  padding-left: 7.84rem;
  position: relative;
}

.why-reson ul:before {
  left: 0;
  top: -20px;
}

.why-reson ul:after {
  right: 0;
  transform: rotate(180deg);
}

.why-reson ul:before,
.why-reson ul:after {
  content: "";
  background-image: url(../images/quote1.png);
  position: absolute;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
}

.exicted-sec {
  padding: 50px 0px;
}

.exicted-sec .exicted-right-panel li>div {
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  padding: 53px 29px;
}

.exicted-sec .exicted-right-panel li>div:before {
  background-image: url(../images/back-shape.png);
  content: "";
  position: absolute;
  left: -30px;
  width: 52px;
  height: 70px;
  background-repeat: no-repeat;
}

.exicted-sec .exicted-right-panel li {
  position: relative;
}

.exicted-sec .exicted-right-panel li+li {
  margin-top: 21px;
}

.exicted-right-panel ul::before {
  content: "";
  background-color: #d9d9d9;
  height: 100%;
  width: 4px;
  position: absolute;
  left: 29px;
  top: 0px;
}

.exicted-right-panel ul::after {
  position: absolute;
  left: 28px;
  height: 101px;
  background-color: #fff;
  content: "";
  top: 0;
  z-index: 2;
  width: 5px;
}

.exicted-sec .exicted-right-panel li:last-child::after {
  content: "";
  position: absolute;
  left: -64px;
  width: 5px;
  height: 50%;
  background-color: #fff;
  bottom: 0px;
}

.why-reson ul {
  position: relative;
}

.exicted-right-panel ul {
  padding-left: 6rem;
}

.exicted-sec .exicted-right-panel li p {
  font-size: 20px;
  color: #808080;
  padding-left: 25px;
  width: calc(100% - 70px);
  margin-bottom: 0px;
}

.exicted-sec .exicted-right-panel li::before {
  content: "";
  background-color: #2a6dfb;
  position: absolute;
  left: -77px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  border: 5px solid #fff;
}

.exicted-sec .exicted-right-panel li figure {
  width: 70px;
  margin-bottom: 0px;
  text-align: center;
}

.slider-content h3 {
  line-height: 32px;
  font-size: 20px;
  padding:15px 0 0 0;
}

.slider-content p {
  margin-top: 13px;
  margin-bottom: 0px;
}

.slider-content h2 {
  margin-bottom: 12px;
}

/* .parter-slider .partner-img {
  margin-left: 23px;
} */

/* 18/8/22 */

.we_offer {
  background-image: url("../images/gray_shape.png"),
    url("../images/how_curve.png"), url("../images/offer_bottombg.jpg");
  background-size: 100% auto, auto;
  background-repeat: no-repeat;
  background-position: top center, left center, bottom center;
  background-color: #f9f9f9;
  padding: 120px 0 0;
}

.we_offer .title {
  margin-bottom: 68px;
}

.offer_main {
  background-color: #fff;
  padding: 60px 20px 0;
}

.offer_content h3 {
  font-family: "linotte-semibolduploaded_file";
  font-size: 26px;
}

.offer_content h1 {
  font-family: "linotte-semibolduploaded_file";
  font-size: 26px;
}

.offer_content p {
  color: #808080;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 30px;
}

.offer_content p span {
  font-size: 20px;
  color: #000;
  font-family: "linotte-semibolduploaded_file";
}

.offer_content {
  width: 59%;
  padding: 0 15px;
}

.offer_img {
  width: 41%;
  padding: 0 15px;
}


.offer_img figure {
  z-index: 7;
}

.offer_2 .offer_img figure:after {
  right: 26px;
}


/* .offer_1 .offer_img::after {
  position: absolute;
  content: "";
  width: 44px;
  height: 119px;
  border-bottom: 2px dashed #a5bbe6;
  border-right: 2px dashed #a5bbe6;
  border-radius: 0 0 60px 0;
  left: 50%;
  transform: translateX(-50%);
  bottom: -97px;
  z-index: 99;
} */

/* .offer_1 .offer_img::before {
  position: absolute;
  content: "";
  background-color: #a5bbe6;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  bottom: 20px;
  z-index: 99;
  margin-left: 13px; 
} */

/* .offer_2 .offer_img::before {
  position: absolute;
  content: "";
  background-color: #a5bbe6;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  top: 5px;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
} */

/* .offer_2 .offer_img::after {
  position: absolute;
  content: "";
  width: 34px;
  height: 119px;
  border-top: 2px dashed #a5bbe6;
  border-left: 2px dashed #a5bbe6;
  border-radius: 60px 0 0 0;
  left: 50%;
  top: -97px;
  z-index: 99;
} */

/* .we_offer .d-flex::before {
  position: absolute;
  content: "";
  bottom: 0;
  width: 50%;
  height: 2px;
  border-bottom: 2px dashed #a5bbe6;
  transform: translateX(-50%);
  left: 50%;
} */

.offer_2 .offer_img {
  padding-top: 20px;
}

.we_offer ul li {
  list-style: disc;
  color: #808080;
  font-size: 20px;
  margin-bottom: 10px;
}

.we_offer ul {
  padding-left: 18px;
  list-style: disc;
}

.offer_inside,
.offer_tool {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* .offer_inside::before {
  position: absolute;
  content: "";
  background-color: #a5bbe6;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  bottom: -10px;
  z-index: 99;
  transform: translateX(-50%);
  left: 50%;
} */

/* .offer_inside::after {
  position: absolute;
  content: "";
  width: 34px;
  height: 119px;
  border-bottom: 2px dashed #a5bbe6;
  border-left: 2px dashed #a5bbe6;
  border-radius: 0 0 0 60px;
  left: 50%;
  bottom: -96px;
} */
/* 
.offer_tool::before {
  position: absolute;
  content: "";
  background-color: #a5bbe6;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  top: -10px;
  z-index: 99;

  left: 50%;
} */

/* .offer_tool::after {
  position: absolute;
  content: "";
  width: 34px;
  height: 119px;
  border-top: 2px dashed #a5bbe6;
  border-right: 2px dashed #a5bbe6;
  border-radius: 0 60px 0 0;
  left: 50%;
  top: -96px;
  transform: translateX(-68%);
} */

.offer_main .d-flex:last-child:before {
  display: none;
}


/* partnerships-page-css */

/**=============================faq====================**/

.help_search {
  border: 1px solid #CCCEDE;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  padding: 13px 10px;
  max-width: 512px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
  background: #fff;
  height: 50px;
  padding-right: 46px;
}

.help_search .form-control {
  border: none !important;
  padding: 0 10px;
  font-size: 16px;
  background-color: transparent;
}

.help_search .btn {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  color: #ffffff;
  height: 100%;
  padding: 14px;
  background-color: #ef4343;
  left: auto;
  font-size: 14px;
  border-radius: 0;
}

.help_form_head {
  padding: 20px 0;
  text-align: center;
}

.help_form_head h4 {
  font-size: 40px;
  font-family: "linotte-bolduploaded_file";
  margin-bottom: 15px;
  color: #222;
}

.help_form_head h1 {
  font-size: 40px;
  font-family: "linotte-bolduploaded_file";
  margin-bottom: 15px;
  color: #222;
}

.faq_page .nav.nav-tabs {
  justify-content: center;
  border: none;
  padding: 50px 0;
}

.faq_page .nav.nav-tabs .nav-item {
  padding: 20px 50px;
  margin: 0 -1px;
  font-size: 20px;
  font-family: "linotte-bolduploaded_file";
  border: 1px solid #D7D9E5;
  color: #222222;
  background-color: #fff;
  border-radius: 0;
}

.faq_page .nav.nav-tabs .nav-item.active,
.faq_page .nav.nav-tabs .nav-item:hover {
  background-color: #ef4343;
  color: #fff !important;
  border-color: #ef4343;
}

.faq_page_main {
  position: relative;
  background: #f9f9f9;
}

.outer_main.faq_page_main:before {
  content: "";
  background-image: url('../images/top_shape.png');
  position: absolute;
  top: 630px;
  height: 480px;
  width: 100%;
  left: 0;
  right: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.browse_by_topic {
  padding: 20px 0;
}

.faq_page {
  position: relative;
  z-index: 2;
}

.browse_by_topic h3 {
  font-size: 24px;
  margin-bottom: 28px;
  color: #222222;
  font-family: "linotte-bolduploaded_file";
  text-align: center;
}

.faq_tabs_main .card-body {
  padding: 5px 24px 24px 50px;
}

.faq-tabs {
  padding: 24px 0;
}

.faq-tabs .title {
  margin-bottom: 20px;
}

.faq_tabs_main {
  border: 1px solid #DDDEE8 !important;
  margin: 0 0 12px 0;
  border-radius: 0px;
  padding: 0;
  background: #fff;
  overflow: hidden;
}

.faq_tabs_main button {
  border: none;
  background: #fff;
  font-size: 18px;
  font-weight: 600;
  color: #222;
  padding: 24px 20px 24px 50px;
  text-align: left;
  width: 100%;
  position: relative;
  font-family: "linotte-bolduploaded_file";
}

.faq_text_content h3 {
  color: #333;
  margin-bottom: 15px;
  font-family: "linotte-bolduploaded_file";
  font-size: 18px;

}

.list_style ol>li:before {
  content: counter(item);
  color: #fff;
  font-size: 16px;
  position: absolute;
  left: -36px;
  width: 28px;
  height: 28px;
  top: 7px;
  background: #0078ff;
  border-radius: 50%;
  text-align: center;
  z-index: 1;
  padding: 5px;
}

.list_style ol>li {
  position: relative;
  padding: 10px 0;
  color: #808080;
  counter-increment: item;
  line-height: 1.5;
  font-size: 16px;
}

.list_style>ol {
  list-style: none;
  counter-reset: item;
}

.list_style ul>li strong {
  color: #000;
  font-size: 18px;
  margin: 0 4px;
  font-family: "linotte-regularuploaded_file";
}

.list_style ol>li:after {
  position: absolute;
  content: '';
  width: 1px;
  height: 100%;
  background: #0e93f0;
  left: -22px;
  top: 26px;
  z-index: 0;
}

.list_style ol>li:last-child:after {
  display: none;
}


.faq_text_content a {
  color: #0078ff;
}

.faq_text_content a.btn {
  background: #0078ff;
  color: #fff;
  border-color: #0078ff;
  font-size: 14px;
}

.faq_text_content ul li {
  list-style: none;
  color: #808080;
  line-height: 1.3;
  font-size: 18px;
  background-image: url('../images/right-arrow.svg');
  background-repeat: no-repeat;
  background-position: 0 15px;
}

.faq_text_content ul>li {
  padding: 12px 0 12px 40px;
}

.faq_text_content ul>li li {
  padding: 6px 0;
  background-image: none;
}

/* .faq_text_content ul li:before{content:"\f054"; font-family:"Font Awesome 5 Free";} */
.verticle-middle {
  vertical-align: text-bottom;
}

.linkpart ul li a figure {
  margin-top: 10px;
  min-height: 54px;
}

.faq_text_content strong {
  color: #333;
  margin: 0 5px;
}

.faq_text_content li a,
.faq_text_content p a {
  margin: 0 5px;
}

.faq_text_content p {
  list-style: none;
  color: #808080;
  line-height: 1.4;
  font-size: 18px;
}


.large_title h3 strong {
  display: inline-block;
  font-size: 52px;
  text-align: center;
  color: #0078ff;
  vertical-align: top;
  margin-right: 15px;
  float: left;
  line-height: 1;
}

.message_detail {
  display: flex;
  align-items: flex-start;
}

.message_icon {
  font-size: 40px;
  margin-right: 15px;
  color: #0078ff;
  background: #ffffff;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  flex: 0 0 80px;
  width: 80px;
  text-align: center;
}

.ans_column {
  background: linear-gradient(180deg, #FBFBFB, #fff);
  padding: 20px;
  height: 100%;
}

.app_direction {
  margin-bottom: 28px;
}

.app_direction a {
  margin-right: 10px;
}

.card_custom {
  text-align: center;
}

.card_custom p {
  margin: 0;
  padding: 10px 0;
}

.card_custom figure {
  margin: 1rem 0;
}

.privacy_link {
  padding: 20px 0;
}

.privacy_link a {
  margin-right: 24px;
}

.privacy_link img:hover {
  filter: blur(1px);
}

.privacy_link img {
  transition: all .3s;
  -webkit-transition: all .3s;
}

.faq_text_content ul {
  margin-bottom: 10px;
}

.card_custom h4 {
  font-family: "linotte-bolduploaded_file";
  font-size: 18px;
}

.card_custom h4 a {
  color: #222;
}

.platform {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}


.platform h5 {
  font-family: "linotte-bolduploaded_file";
  font-size: 18px;
  flex-grow: 1;
  max-width: calc(100% - 100px);
}

.message_icon_message {
  max-width: calc(100% - 100px);
}

.work_flow_list ul li {
  background: no-repeat;
  padding-left: 0;
  display: flex;
  align-items: center;
}

.work_flow_list ul li figure {
  flex: 0 0 64px;
  max-width: 64px;
  text-align: center;
  margin-right: 10px;
}

.work_flow_list ul li h5 {
  flex: 0 0 100%;
  max-width: calc(100% - 80px);
}


.card_custom.relationship_grid ul li {
  text-align: left;
  font-size: 15px;
  padding-left: 24px;
  background-size: 15px;
  background-position: 0 15px;
}

.card_custom.relationship_grid {
  text-align: left;
}

.outer_main.faq_page_main {
  margin-bottom: 80px;
}

.outer_main.faq_page_main {
  margin-bottom: 80px;
}

.outer_main.faq_page_main:after {
  list-style: none;
  color: #808080;
  line-height: 1.3;
  font-size: 18px;
  background-image: url('../images/bottom_shape.png');
  background-repeat: no-repeat;
  background-position: 0 15px;
  position: absolute;
  bottom: 0px;
  height: 340px;
  width: 100%;
  background-size: 100% 100%;
  right: 0;
}

.resource_hub_grid {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 15px;
}

.resource_hub {
  display: flex;
  flex-wrap: wrap;
}


.faq_text_content ul.stepsbox {
  display: flex;
  flex-wrap: wrap;
}

.faq_text_content ul.stepsbox li {
  margin-right: 30px;
  border: 1px solid #222;
  padding: 10px 15px;
  background-image: none;
  position: relative;
  margin-bottom: 10px;
  color: #222;
}

.faq_text_content ul.stepsbox li:last-child:after {
  display: none;
}

.faq_text_content ul.stepsbox li:after {
  content: "";
  background-image: url('../images/right-arrow.svg');
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  background-repeat: no-repeat;
  right: -30px;
  top: 15px;
}

.large_title h3 small {
  margin-left: 10px;
  color: #222;
  display: block;
  font-size: 16px;
}

.large_title h3 {
  color: #0078ff;
  font-size: 28px;
}

.relationship_grid figure {
  display: flex;
  align-items: center;
  margin: 0;
}

.relationship_grid figure {
  margin-left: -16px;
}

.collapse_toggle_inner i {
  color: #ed574e;
}


.collapse_toggle_inner {
  position: absolute;
  left: 20px;
  top: 24px;
}

.pagination li a {
  padding: 7px 10px;
  margin: 0 3px;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 3px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
}


.pagination {
  justify-content: center;
  margin: 0px 0 20px 0;
  /* overflow: scroll; */
}

.pagination li a:hover,
.pagination li.active a {
  background-color: #2a6dfb;
  color: #fff !important;
}


.program_form .form-control {
  padding: 10px 15px;
  font-size: 16px;
}

.program_form {
  width: 60%;
  padding: 24px;
}

.program_form .loginsubmit {
  padding: 10px;
  font-size: 18px;
}

.form_block {
  background-color: #f1f1f1;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
}

.program_form h3 {
  font-size: 28px;
  margin: 10px 0 15px 0;
  text-align: left;
}


/**====pre-registration===**/
.preregisterationFrom {
  max-width: 716px;
}

.preregisterationFrom .loginform {
  width: 100%;
  padding: 32px;
}

.preregisterationFrom .loginform h3 {
  font-size: 28px;
  margin: 0 0 15px 0;
}

.welcome-text {
  font-size: 24px;
  font-weight: bold;
  color: #0078FF;
  display: block;
  margin-bottom: 15px;
}

.preregisterationFrom .loginlogo img {
  max-width: initial;
}

.registration_head .logo img {
  max-width: initial;
  width: 50%;
}

.preregisterationFrom .logo {
  display: block;
}

.champinoned-powered {
  display: flex;
  justify-content: space-between;
  padding: 0 144px;
  align-items: center;
}

.champinoned-grid {
  text-align: left;
  flex-grow: 1;
}

/* .champinoned-grid a img {
  max-height: 50px;
  width: auto;
} */

.champinoned-grid a {
  display: inline-block;
  padding: 0 10px;
}


.champinoned-grid span {
  padding: 0 5px;
}

.champinoned-grid h6 {
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  padding-bottom: 10px;
}

.champinoned-grid span img {
  max-height: 50px;
}

.semi-header {
  padding: 15px 0;
}

.semi-header strong {
  font-weight: bold;
  color: #EF4343;
  font-size: 38px;
  margin: 10px 0 32px 0;
  display: block;
}

.form_small_heading {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  text-align: left;
  color: #222222;
}

.radio_group {
  display: flex;
  margin: 10px 0;
}

.radio_group .form-check {
  margin-right: 19px;
  font-size: 18px;
  color: #A8A8A8;
  font-weight: 100;
}

.radio_group .form-check input[type="checkbox"] {
  width: 20px;
  height: 20px;
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.remember_me {
  padding: 15px 0px;
  font-size: 14px;
  color: #222222;
}

.remember_me a {
  color: #0078FF;
}

.registration_form_wrapper {
  padding: 5rem 0;
}














.v-lookup-down {
  padding-top: 24px;
  justify-content: center;
  align-items: center;
}

.v-lookup-down .champinoned-grid.company_logo {
  text-align: center;
}

.v-lookup-down h6 {
  text-align: center;
}


.preregisterationFrom .center_title h6 {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  padding-bottom: 20px;
}

.champinoned-grid span {
  margin: 11px 26px;
}

.achievements_list li img {
  max-height: 32px;
  margin-bottom: 10px;
}

.pre-register .mainImg {
  max-height: 60px !important;
}

.pre-register span {
  padding: 0;
  margin: 0;
}

.pre-register {
  width: 130px;
}

.threedots:after {
  content: "\2807";
  font-size: 30px;
}

/* New Section for Apps */
.download_app_detail strong {
  font-size: 40px;
  color: #222222;
  font-family:"linotte-regularuploaded_file";
  font-weight:400;
}

.download_app_detail p {
  font-size: 24px;
  color: #222222;
  padding: 8px 0 15px 0;
  margin-bottom: 0;
}

.app_store_link a {
  display: inline-block;
  margin-right: 16px;
}

.app_store_link {
  padding: 10px 0;
}

.scane_qr .qr_scanbox {
  background: #fff;
  display: inline-block; 
  /* margin-right: 36px; */
  width: 100px;
  /* border: 1px solid #eef2f9; */
}

.scane_qr h6 {
  font-size: 24px;
  color: #222222;
  font-weight: 600;
  margin-bottom: 15px;
}

.scane_qr {
  padding: 26px 0 0 0;
}

.download_app_section {
  padding: 64px 0 12px 0;
  overflow-y: hidden;
}

.app_screen {
  text-align: right;
}

/* New Section for Apps */


.qr-wrapper {
  float: left;
  margin-right: 36px;
}

.qr-wrapper strong {
  display: block;
  font-size: 14px;
  color: #000;
  text-align: center;
  width: 100%;
  padding-top: 6px;
}

.extar_info_link ul {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.extar_info_link ul li {
  padding: 0 5px;
}

.mentor_log {
  margin: 0 -5px;
}

.mentor_log img {
  max-height: 40px;
}

.health_check img {
  width: 40px;
}

.error-class {
  color: #dd2a2a;
  font-size: 14px;
}

.Toast-style_toast-content__k0f03 {
  word-break: unset !important;
}

.button-large-style {
  padding: 10px 20px;
  min-width: 200px;
  font-size: 21px;
}

.olay img {
  max-height: 32px;
  margin-top: 3px;
}



/* partnership-page */


.programs_are_driven {
  padding: 100px 0;
}



.programs_are_driven_point li a {
  display: flex;
  align-items: center;
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 12px 20px;
  width: 100%;
  margin-bottom: 20px;
  color: #222222;
  font-size:18px;
  line-height:1.4;
}

.programs_are_driven_point li a span {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 30px;
}

.we-offer-point li {
  position: relative;
  padding-left: 20px;
  line-height: 1.5;
  color: #808080;
  margin-bottom: 15px;
}

.we-offer-point li:before {
  background-image: url(../images/red_tick.png);
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  top: 10px;
  left: 0;
}

.benifits_list .offer_content h3 {
  color: #222222;
}

.benifits_list .offer_content p {
  font-size: 20px;
  line-height: 1.5;
  color: #808080;
}

.benifits_list .offer_img img {
  position: relative;
  z-index: 2;
}

.top_dotted {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.bottom_dotted {
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 1;
}

.benifits_list .offer_img figure {
  padding-left: 24px;
  padding-bottom: 24px;
}

.benifits_list .offer_content p {
  margin-bottom: 15px;
}

.benifits_list {
  padding-bottom: 70px;
}

.benifits_list:nth-child(even) {
  flex-direction: row-reverse;
}

.partnership-page-inner h1 {
  margin-bottom: 20px;
}

.we-offer h3 {
  font-size: 26px;
  margin-bottom: 15px;
  font-weight: bold;
}


.connect_with_us {
  background: #ecf2fd;
  padding: 15px 24px;
  border-radius: 8px;
  border: 1px solid #2a6dfb;
  display: flex;
  max-width: 910px;
  margin: 32px auto 15px auto;
  line-height: 1.5;
  align-items: center;
}

.connect_with_us figure {
  flex: 0 0 64px;
  max-width: 64px;
  padding-right: 20px;
  margin-bottom: 0;
}

.connect_with_us figcaption {
  flex: 1;
  max-width: calc(100% - 64px);
  font-size: 20px;
  color: #222222;
}

.connect_with_us figcaption a {
  color: #2a6dfb;
}

.connect_with_us figure img {
  max-width: 100%;
}


.partner_ship {
  background-image: url("../images/gray_shape.png"),
    url("../images/how_curve.png"), url("../images/partnership_bottom.png");
  padding-bottom: 130px;
}

.partner_brand {
  padding-top: 0;
  padding-bottom:64px;
}

.partner_brand .brand img {
  max-width: 100%;
}

.partner_brand .brand {
  padding: 0 15px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partnership-page-inner .inner-partner {
  max-width: 100%;
}
.client_slider {
  padding: 0 15px;
}


.client_slider_inner {
  padding: 10px;
  background: #ffff;
  border-radius: 8px;
  box-shadow: 0 5px 8px rgba(0,0,0,.1);
  border: 1px solid #ddd;
}

.rounder-img img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 19px;
  margin-bottom: 10px;
}
.datepicker-metting .MuiInput-underline{ 
  border: 1px solid #00000029;
  padding: 8px;
  border-radius: 7px;
  margin-top: 26px;
}

.datepicker-metting .MuiInput-underline::before{
  border: none!important;
}
.datepicker-metting label{
  font-size: 22px!important;
    color: #353535!important;
}
.datepicker-metting .MuiInput-underline::before::hover{
  border: none!important;
}

.transparent-btn-cancel,.transparent-btn-cancel:hover {
  background: transparent;
  color: #ef4343;
}
.transparent-btn-cancel:hover {
  border-color: #ef4343;
}

.close-btn-modal button.close {
  font-size: 43px;
  right: 37px;
}
.close-btn-modal{
  font-size: 25px;
  font-weight: 700;
}

.welcome-table td{
  border: 1px solid #eaeaea;
}

.fc-dayGridMonth-button, .fc-today-button{
  display: none !important;
}

table td{white-space:wrap!important;}

.country_flag{
  max-width:25px;
  margin-right:10px;
  margin-top: -8px;
}

 
.AvailabilityStatus {
  background: #2a6dfb;
  color:#fff;
  font-size: 14px;
  padding: 4px 10px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 8px;
  border-radius: 3px;
  box-shadow: 0 4px 5px rgba(0,0,0,.1);
}

.tagPoint{background-color:#f1f1f1; font-size:14px; padding:5px 10px; border-radius:4px; margin-right:5px; margin-bottom:5px; display:inline-block;} 
 
.UnavailabilityStatus {
  background-color:#ef4343; 
  color:#fff;
  font-size: 14px;
  padding: 4px 10px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 8px;
  border-radius: 3px;
  box-shadow: 0 4px 5px rgba(0,0,0,.1);
   
}
.extar_info_link li img {
  max-height: 30px;
  width: auto!important;
}

.menteesCols {
  padding: 0px 15px 10px 15px;
  flex: 0 0 25%;
  max-width: 25%;
}

/* .mentor_info h3,.mentor_info h6,.mentor_info h5,.mentor_info p { 
 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
} 
*/



/* new home */



.caption_Secondary {
  padding-top: 24px;
  margin-top: 34px;
  border-top: 1px solid #EFEFEF;
  max-width: 480px;
}

.caption_Secondary strong {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
  display:block;
}

.home_banner_block 
 .col-md-5 {
    flex: 0 0 36%;
    max-width: 36%;
}

.home_banner_block .col-md-7 {
    flex: 0 0 64%;
    max-width: 64%;
}

.how_sec .icon_box .box {
  padding-top: 0;
}

.how_sec .descraption a {
  color: #343A40;
}

.secondaryTitle{font-family: "linotte-regularuploaded_file"; color:#222222;}


.slick-prev:before, .slick-next:before{content: ">"; }




.leader_info2{padding:0 10px 10px 10px;}
.leader_info2 span{display:block; line-height:1.6;}

.leader_box:hover .LinkedinLink{transform:scale(1);}


.LinkedinLink {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0 0 0 / 75%);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: all ease-in-out .3s;
  -webkit-transition: all ease-in-out .3s;
  transform: scale(0);
}

.LinkedinLink .newcustomelinked {
  /* background: rgb(239 67 67 / 75%); */
  background-color:transparent;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #FFFFFF;
  text-decoration:none;
}
.LinkedinLink .newcustomelinked:hover{ background: rgb(239 67 67 / 75%); }

.members_tag img{width:29px; height:18px; margin-right:8px;}

.slick-prev:before{
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  border-top: 2px solid #CBCBCB;
  border-right: 2px solid #CBCBCB;
  transform: rotate(-136deg);
  }
  
  .slick-next:before{
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    border-right: 2px solid #CBCBCB;
    border-bottom: 2px solid #CBCBCB;
    transform: rotate(-45deg);
    }
  
    .leader_sec .slick-prev,.communities_sec .slick-prev {
      position: absolute;
      top: -20px;
      right: 84px;
      left: auto;
  }
  
  .leader_sec .slick-next,.communities_sec .slick-next {
      position: absolute;
      top: -20px;
      right: 36px;
      left: auto;
  }

  .start-building{display:none;}

  .testimonialMember {
    margin-top: -54px;
}
.communities_sec .slick-slider {
  padding-top: 32px;
}

.members_sec .slick-prev:before,.members_sec .slick-next:before {
  border: none;
  transform: rotate(0);
  position: initial;
}

.about_who_sec .about_content {
  max-width: 430px;
}.members_sec .slick-slide {
  padding: 0 10px;
}

section.about_who_sec .who_box {position: relative;}

section.about_who_sec .who_box:after {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    width: 100%;
    background: #00000050;
    opacity:0;
}


section.about_who_sec .who_box:hover:after{opacity:1;}


.about_content_mian .about_content_mian{padding:0;}

 .slick-next:hover:before,  .slick-prev:hover:before{border-color: #ef4343!important;}
 .modal,.modal-backdrop {
  z-index: 9999;
}

 


.diversity .slick-track
{
    display: flex !important;
}

.diversity .slick-slide
{
    height: inherit !important;
}
.diversity .slick-slide>div, .diversity .slick-slide>div>div,.diversity .slick-slide>div>div>div {
  height: 100%;
}
.members_sec .member_img {
  max-width: 232px;
}

.members_sec .slick-track {
  display: flex;
  justify-content:center;
}

.members_sec .slick-track .slick-slide {
  flex: 0 0 auto;
  width:248px!important;
}
.members_sec .slick-track .slick-slide .advisor-contnt {
  padding: 0;
}

.members_sec .slick-track .slick-slide .advisor-contnt .readmore {
  cursor: pointer; display:inline-block;
}.readmore {
  display: inline-block!important;
  width: auto!important;
  cursor: pointer;
} 

 
 

ul.sociallink li a .withhoverShow {display:none;}
ul.sociallink li a:hover .withhoverShow {display:inline-block;}
ul.sociallink li a:hover .withouthoverShow{display:none;}
 
.MuiDialog-root {
  z-index: 99999!important;
}



.MuiTooltip-popper {
  z-index: 9999!important;
}
.modal-open .tooltip {
  opacity: 1;
  z-index: 9999;
}
 
.igniteSectionOuter{padding-top:100px;}
.igniteSection {
  background: #7823DC;
  position: relative;
  line-height: 0;
}

.igniteBannerRow {
  display: flex;
  justify-content: end;
}

.banner-Cap { 
  color: #fff;
  line-height: 1.5;
  max-width:50%;
}
.banner-Cap-outer{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  right: 0;
}
.banner-Cap h1 {
  font-size: 36px;
  font-weight: normal;
  margin: 15px 0;
}

.poweredBY {
  margin-bottom: 35px;
}

.poweredBY strong {
  display: block;
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 10px;
}

.poweredBY span {
  background: #fff;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 10px;
}

.bannerShapelogo {
  position: absolute;
  bottom: 104px;
  left: 122px;
}

.bannerShapeImg {
  position: relative;
}


.bannerShapeImg {
  max-width: 620px;
}

/*
DOID banner Css
*/


.bannerDOID figure img {
  width: 100%;
}

.bannerDOID figure {
  margin: 0;
}

.bannerDOID {
  position: relative;
}

.DOIDBannerContent {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.DOIDBannerContent h1 {
  color: #fff;
  font-size: 48px;
  font-weight: normal;
}

.DOIDBannerContent h1 strong {
  font-size: 54px;
  color: #afcb37;
  display: block;
}

.DOIDBannerContent h1 small {
  font-size: 32px;
}

.powered-champianed span {
  display: block;
  color: #fff;
  font-size: 20px;
}

.powered-champianed {
  display: flex;
}

.DoIdLogoWrapper {
  display: block;
  background: #fff;
  padding: 15px 24px;
  border-radius: 10px;
  width: auto;
  min-height: 58px;
  text-align: center;
  margin-top: 16px;
}

.DoIdLogoWrapper img {
  max-height: 58px;
}
.powered-champianed{margin-top:32px;}

.powered-champianed > div {
  margin-right: 18px;
}




.email_Multi {
  margin: 15px 0 0 0;
  background: #ddd;
  padding: 12px 24px;
  border-radius: 6px;
  display: inline-block;
  font-weight: 600;
  line-height:1.8;
}

.email_Multi span {
  background: #f0f0f0;
  padding: 2px 20px;
  border-radius: 100px;
  margin: 0 5px;
  display:inline-block;
  line-height:1.4;
  
}







/* banner section */ 
.witLandingBanner {
  background: #4D4DFF;
  padding:40px 0 20px 0;
}

.WitbannerOuter {
  display: flex;
  align-items: center;
}

.WitbannerOuter figure {
  margin:24px 0;
  flex: 0 0 324px;
  max-width: 324px;
  flex-shrink: 0;
}
.WitbannerOuter figure img{width:100%;}

.WitbannerOuter figcaption {
  padding-left: 136px;
  flex: 1;
}

.WitbannerOuter figcaption h1 {
  margin: 28px 0 24px 0;
  font-size: 54px;
  color: #fff;
  line-height: 1.2;
  font-weight: bold;
}

.WitbannerOuter figcaption p {
  font-size: 48px;
  color: #fff;
} 

.powerdBYLOGO {
    display: flex;
}

.powerdBYLOGO > div {
    margin-right: 50px;
}
 
.powerdBY {
  padding-top: 64px;
}
.powerdBY strong{display:block; margin-bottom:10px;}

.powerdBY strong {
  color: #fff;
  font-weight: normal;
  font-size: 20px;
}
.SCSLogoOuter{text-align:right;}

@media screen and (max-width: 1199px) {
.WitbannerOuter figcaption{    padding-left: 100px;}
.WitbannerOuter figcaption h1{font-size:44px;}
.WitbannerOuter figcaption p{font-size:36px;}

}
@media screen and (max-width: 991px) {
.WitbannerOuter figcaption h1{font-size:36px;}
.WitbannerOuter figure {
  flex: 0 0 280px;
  max-width: 280px;
}

.WitbannerOuter figcaption {
  padding-left: 54px;
}

.WitbannerOuter figcaption h1 {
  font-size: 36px;
}

.WitbannerOuter figcaption p {
  font-size: 30px;
}

.powerdBY {
  padding-top: 24px;
}

.witcircle{max-height:36px;}

.WitbannerOuter figcaption h1 {
  margin: 24px 0 20px 0;}
  .powerdBYLOGO > div {
    margin-right: 30px;
}
}

@media screen and (max-width: 767px) {

  .WitbannerOuter figure {
    flex: 0 0 240px;
    max-width: 240px;
}

.WitbannerOuter figcaption {
    padding-left: 20px;
}

.WitbannerOuter figcaption h1 {
    font-size: 24px;
    margin: 10px 0 0;
}

.WitbannerOuter figcaption p {
    font-size: 20px;
    margin: 10px 0;
}
.witcircle{max-height:32px;}

.powerdBY  img {
    max-height: 48px;
}

.powerdBY strong {
    font-size: 18px;
}

.SCSLogoOuter img {
    max-height: 30px;
}

.witLandingBanner {
    padding: 32px 0 0 0;
}

.powerdBY {
    margin-bottom: 10px;
}

.WitbannerOuter figure {
  flex: 0 0 200px;
  max-width: 200px;
}
.powerdBYLOGO > div {
  margin-right: 20px;
}

}



@media screen and (max-width: 575px) {

  .WitbannerOuter {
    flex-direction: column-reverse;
}

.WitbannerOuter figure {
    max-width: 220px;
    max-width: 220px;
}

.WitbannerOuter figcaption {
    text-align: center;
}

.WitbannerOuter figcaption {
    flex: 1;
    width: 100%;
    padding: 0;
}

.SCSLogoOuter {
    text-align: center;
}
.WitbannerOuter figcaption h1{font-size:28px;}
.powerdBY img {
  max-height: 24px;
}
.powerdBYLOGO{justify-content:center;}

}
 