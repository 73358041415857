 .banner_fix {
     position: relative;
     padding: 84px 0 264px 0;
 }

 .banner_fix:before {
     content: "";
     position: absolute;
     background: #D74142;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     height: calc(100% - 290px);
 }
 .banner_right_pic img {max-height: 520px;}
 .banner_fix::after {
     background-image: url(../images/banner_shape.svg);
     background-size: cover;
     background-repeat: no-repeat;
     background-position: bottom right;
     position: absolute;
     top: auto;
     bottom: 0;
     left: 0;
     right: 0;
     height: 300px;
 }


 .mentoring_banner_caption {
     position: relative;
     color: #fff;
 }

 .mentoring_banner_caption h1 small {
     display: block;
     font-weight: 100;
 }

 .mentoring_banner_caption h1 strong {
     /* background: #970806;
     padding: 10px; */
     /* display: inline-block; */
     margin: 8px 0;
 }

 .mentoring_banner_caption h1 {
     font-size: 43px;
     
 }

 .brand_logos {
     display: flex;
     flex-wrap: wrap;
     padding: 40px 0;
 }

 .vlookup_logo strong {
     font-size: 16px;
     display: block;
     margin-bottom: 15px;
 }

 .vlookup_logo img {
     max-height: 40px;
 }

 .vlookup_logo {
     padding-right: 40px;
 }

 .banner_right_pic {
     position: absolute;
     right: 0;
     top: 0;
     z-index: 9;
 }

 .outer_main.mentoring_page {
     padding: 2rem 0 2rem;
     background: #fff;
 }



 @media screen and (max-width: 1366px) {
     .mentoring_banner_caption h1 {
         font-size: 36px;
     }

     .vlookup_logo img {
         max-height: 54px;
     }

     .banner_right_pic img {
         max-height:480px;
     }

 }

 @media screen and (max-width: 1199px) {
     .banner_fix::after {
         height: 164px;
     }

     .banner_fix {
         position: relative;
         padding: 64px 0 164px 0;
     }

     .banner_right_pic img {
         max-height: 380px;
     }

     .mentoring_banner_caption h1 {
         font-size: 30px;
     }

     .vlookup_logo img {
         max-height: 44px;
     }

     .banner_fix:before {
         height: calc(100% - 164px);
     }

 }

 @media screen and (max-width:991px) {
    .mentoring_banner_caption h1 {
        font-size: 51px;
        max-width: 420px;
        line-height: 1.8;
    }
     .banner_right_pic img {
         max-height: 380px;
     }

     .mentoring_banner_caption h1 {
         font-size: 28px;
     }

     .mentoring_banner_caption h1 strong {
         margin: 0;
     }

     .brand_logos {
         padding: 24px 0;
     }

     .banner_fix::after {
         height: 124px;
     }

     .banner_fix:before {
         height: calc(100% - 124px);
     }

     .banner_fix {
         padding: 64px 0 124px 0;
     }

 }

 @media screen and (max-width:767px) {

     .banner_right_pic img {
         max-height: 280px;
     }

     .mentoring_banner_caption h1 {
         font-size: 24px;
         line-height:1.2;
         text-align:center;
     }



     .mentoring_banner_caption h1 strong {
         margin: 0;
     }

     .brand_logos {
         padding: 20px 0;
     }

     .banner_fix::after {
         height: 100px;
     }

     .banner_fix:before {
         height: calc(100% - 100px);
     }

     .banner_fix {
         padding: 24px 0 100px 0;
     }

     .vlookup_logo img {
         max-height: 34px;
     }
     .mentoring_banner_caption h1 {
        margin-left: 0;
        margin-right: auto;
        text-align: left;
        line-height: 1.5;
        max-width: 350px;
    }
    .mentoring_banner_caption h1 strong {
        padding: 0;
        background: transparent;
    }
 }

 @media screen and (max-width:575px) {
    .mentoring_banner_caption h1 strong {
        padding: 5px 10px;
        font-size: 20px;
        background-color: transparent;
        text-align:center;
    }
     .banner_right_pic {
         position: relative;
         bottom: 0;
         z-index: 9;
         width: 100%;
         text-align: center;
         padding-top: 0;
     }

     .mentoring_banner_caption {
         text-align: center;
     }

     .vlookup_logo {
         padding-right: 15px;
     }

     .brand_logos {
         justify-content: center;
     }.mentoring_banner_caption h1 {
        max-width: 100%;
        text-align: center;
    }

 }

 @media screen and (max-width:375px) {
     .banner_fix::after {
         height: 60px;
     }

     .banner_fix:before {
         height: calc(100% - 60px);
     }

     .banner_fix {
         padding: 24px 0 60px 0;
     }
 }