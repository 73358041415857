



/*======================
    404 page new
=======================*/


.page_404 {
    padding: 162px 0 40px;
    background: #fff;
    font-family: 'Arvo', serif;
    
  }
  
  .page_404 img {
    width: 100%;
  }
  
  .four_zero_four_bg {
  
    background-image: url(../images/404-page.png);
    height: 400px;
    background-position: center;
  }
  
  
  .four_zero_four_bg h1 {
    font-size: 80px;
  }
  
  .four_zero_four_bg h3 {
    font-size: 80px;
  }
  
  .link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
  }
  
  .contant_box_404 {
    margin-top: -50px;
  }
  
  .already-submit-main {
    height: 502px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media (max-width: 960px) {
    .already-submit-main {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  @media(max-width:575px){
    .four_zero_four_bg {
      height: 280px;
      background-position: center;
      background-size: 500px;
      background-repeat: no-repeat;
  }
  .contant_box_404 {
    margin-top: 0px;
}
.page_404 {
  padding: 90px 0 40px;
}
  }
  
  
  .max-height-275{
    max-height: 275px;
  }
  
  .discount-code-dev {
    width: 200px !important; 
  }
  
  
  
  /* send review title start*/
  
  .text-area-field input:focus{outline: none;
      border-color: transparent;
      box-shadow: 0 0 4px 0 #c79f49!important;}
  
      /* send review title end*/

  